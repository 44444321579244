import React, { useEffect, useState, useRef } from 'react';
import serviceAdmin from '../services/serviceAdmin';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import useColor from '../context/themeContext'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import SubscriptionExport from '../componentsAdmin/Export/SubscriptionExport'
import SessionsExport from '../componentsAdmin/Export/SessionsExport'
import SurveyExport from '../componentsAdmin/Export/SurveyExport'
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TableImport from '../componentsAdmin/Table/TableImport'
import MenuItem from '@material-ui/core/MenuItem';
import SearchInput from "../componentsAdmin/Search/SearchInput"
import Zoom from '@material-ui/core/Zoom';
import FilterListIcon from '@material-ui/icons/FilterList';
import Tooltip from '@material-ui/core/Tooltip';
import useAlert from '../hooks/useAlert'
import { borderRadius, select, buttonSuccess, buttonProgress, filterIcon, filterIconActive, colorLabel } from '../classes'
import moment from 'moment'
import 'moment/locale/it';
moment.locale('it')





const Csv = (props) => {
  const [options, setAlert] = useAlert()
  const { COLORS } = useColor();
  const useStyles = makeStyles((theme) => ({
    tooltip: {
      fontSize: "28px",
    },
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
    },
    ...buttonSuccess(COLORS),
    buttonProgress,
    ...colorLabel(COLORS),
    select,
    filterIcon,
    ...filterIconActive(COLORS)
  }));
  const CustomTooltip = withStyles({
    tooltip: {
      fontSize: "14px",
      backgroundColor: COLORS.primary,
      color: "#fff",
      textAlign: "center",
      borderRadius: "10px",
      fontWeight: "bolder"
    }
  })(Tooltip);
  const classes = useStyles();

  const isMount = useRef(true);

  const [surveys, setSurveys] = useState([]);
  const [groups, setGroups] = useState([])
  const [roles, setRoles] = useState([])
  const [courses, setCourses] = useState([])
  const [userMe, setUserMe] = useState({});
  const [loadingSession, setLoadingSession] = useState(false);
  const [loadingSub, setLoadingSub] = useState(false);
  const [loadingSurvey, setLoadingSurvey] = useState(false);
  const [loading, setLoading] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [rowsImport, setRowsImport] = useState(10);
  const [pageImport, setPageImport] = useState(1);
  const [page, setPage] = useState(0);
  const [exports, setExports] = useState([])
  const [count, setCount] = useState(0)
  const [reloadSub, setReloadSub] = useState(false);
  const [ordering, setOrdering] = useState("")
  const [search, setSearch] = useState("")
  const [show, setShow] = useState(false)

  const setloadingsub = (input) => { setLoadingSub(input) }
  const setloadingsession = (input) => { setLoadingSession(input) }
  const setloadingsurvey = (input) => { setLoadingSurvey(input) }
  const setbackdrop = (input) => { setBackdrop(input) };
  const setrowsimport = (input) => { setRowsImport(input) };
  const setpage = (input) => { setPage(input) };
  const setpageimport = (input) => { setPageImport(input) };
  const setreloadsub = () => { setReloadSub(!reloadSub) }



  useEffect(() => {
    let url = `/v1/synchronization/data-exports/?page=${pageImport}&page_items=${rowsImport}`;
    if (ordering) url += `&ordering=${ordering}`;
    if (search) url += `&search=${search}`;
    const timeSearch = setTimeout(() => {
      serviceAdmin.getService(url)
        .then(res => {
          if (isMount.current) {
            if (res.data.data) {

              setCount(res.data.meta.pagination.items_count)
              setExports(res.data.data.map((i) => {
                return {
                  "id": i.id,
                  "user": i?.user.first_name + " " + i?.user.last_name,
                  "created": moment(i?.created).format('DD-MM-YYYY HH:mm'),
                  "type": i?.additional_data?.type,
                  "annotation": i?.additional_data?.annotation,
                  "audit_filters": i?.additional_data?.audit_filters,
                  "file": i?.file_output,
                  "success": i?.queue_operation_error === false && i?.success && i?.ended ? true : false,
                }
              }));
              setBackdrop(false)
              setPage(pageImport - 1)
            }
          }
        }).catch(err => {
          if (err?.status === 404) {
            setBackdrop(false)
          }
        })
    }, 350)

    return () => {
      clearTimeout(timeSearch)
    }
  }, [pageImport, rowsImport, reloadSub, ordering, search])


  useEffect(() => {

    serviceAdmin.getService("v1/users/me/")
      .then(res => {
        if (isMount.current) {
          if (res.data?.data) {
            setUserMe(res.data.data)
          }
        }
      }).catch(err => {
        console.log(err)
      })

    serviceAdmin.getService("v1/surveys/")
      .then(res => {
        if (isMount.current) {
          if (res.data?.data) {
            setSurveys(res.data.data)
          }
        }
      }).catch(err => {
        console.log(err)
      })

    serviceAdmin.getService("v1/courses/")
      .then(res => {
        if (isMount.current) {
          setLoading(true)
          if (res.data?.data) {
            setCourses(res.data.data)
          }
        }
      }).catch(err => {
        console.log(err)
      })

    return () => {
      isMount.current = false;
    }

  }, [])

  useEffect(() => {
    if (userMe?.role?.tier === "BO_COM") {
      serviceAdmin.getService("v1/groups/")
        .then(res => {
          if (isMount.current) {
            setGroups(res.data.data);
          }
        }).catch(err => {
          console.log(err);
        })
      serviceAdmin.getService("v1/roles/")
        .then(res => {
          if (isMount.current) {
            setRoles(res.data.data);
          }
        }).catch(err => {
          console.log(err);
        })
    }
  }, [userMe])


  return (
    <Container maxWidth={"xl"} style={{ marginTop: "30px" }}>
      <Grid container spacing={2} style={{ marginBottom: "50px" }}>

        <Fade in={loading} timeout={{ appear: 100, enter: 400, exit: 400 }}>
          <Grid item lg={6} xs={12}>
            <SubscriptionExport
              setLoadingSub={setloadingsub}
              userMe={userMe}
              setReloadSub={setreloadsub}
              loadingSession={loadingSession}
              loadingSub={loadingSub}
              loadingSurvey={loadingSurvey}
              roles={roles}
              groups={groups}
              courses={courses}
              setCustomAlert={props.setCustomAlert}
            />
          </Grid>
        </Fade>

        <Fade in={loading} timeout={{ appear: 100, enter: 400, exit: 400 }}>
          <Grid item lg={6} xs={12}>
            <SurveyExport
              setLoadingSurvey={setloadingsurvey}
              userMe={userMe}
              setReloadSub={setreloadsub}
              loadingSub={loadingSub}
              loadingSurvey={loadingSurvey}
              loadingSession={loadingSession}
              roles={roles}
              groups={groups}
              courses={courses}
              setCustomAlert={props.setCustomAlert}
              surveys={surveys}
            />
          </Grid>
        </Fade>

        <Fade in={loading} timeout={{ appear: 100, enter: 400, exit: 400 }}>
          <Grid item lg={6} xs={12}>
            <SessionsExport
              setLoadingSession={setloadingsession}
              userMe={userMe}
              setReloadSub={setreloadsub}
              loadingSession={loadingSession}
              loadingSub={loadingSub}
              loadingSurvey={loadingSurvey}
              roles={roles}
              groups={groups}
              courses={courses}
              setCustomAlert={props.setCustomAlert}
            />
          </Grid>
        </Fade>
      </Grid>


      <Fade in={loading} timeout={{ appear: 100, enter: 400, exit: 400 }}>
        <Grid item xs={12}>
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <SearchInput noMargin searchString={search} placeholder={"Cerca..."} onChange={event => setSearch(event.target.value)} />
            <Zoom in={true} timeout={{ appear: 400, enter: 500, exit: 500 }}>
              <CustomTooltip className={classes.tooltip} title={show ? "Nascondi filtri" : "Mostra filtri"} placement="top">
                <FilterListIcon onClick={() => setShow(!show)} className={show ? classes.filterIconActive : classes.filterIcon} />
              </CustomTooltip>
            </Zoom>
          </Grid>
          <Paper style={{ borderRadius: borderRadius, padding: "20px", marginTop: "20px" }}>

            <div style={{ display: "flex", alignItems: "flex-start", padding: "20px 0px 40px 20px" }}>

              <h2 style={{ color: COLORS.primary }} className={"label-attivita"}>Lista Export</h2>
              {show &&
                <div style={{ display: "flex", flexDirection: "column", marginLeft: "30px" }}>

                  <InputLabel style={{ width: "100%", textAlign: "center", fontSize: "17px", marginBottom: "15px" }}>
                    Ordina per:
                  </InputLabel>
                  <Select inputProps={{ input: classes.input }} variant="outlined" value={ordering || ""} onChange={(event) => { setPageImport(1); setOrdering(event.target.value); }} className={classes.select} name="ordering">
                    <MenuItem className={classes.colorLabel} value={""}>Nessun ordinamento</MenuItem>
                    <MenuItem className={classes.colorLabel} value={"created"}>Data creazione: crescente</MenuItem>
                    <MenuItem className={classes.colorLabel} value={"-created"}>Data creazione: decrescente</MenuItem>
                  </Select>

                </div>
              }
            </div>

            <TableImport
              headcell={[{ name: "Utente" }, { name: "Etichetta" }, { name: "Data creazione", class: "hide-column-md" }, { name: "Tipo export" }, { name: "Esito operazione", class: "hide-column-md" }, { name: "Download export" }, { name: "" }]}
              imports={exports}
              count={count}
              setBackdrop={setbackdrop}
              backdrop={backdrop}
              setRowsImport={setrowsimport}
              rowsImport={rowsImport}
              page={page}
              setPage={setpage}
              pageImport={pageImport}
              setPageImport={setpageimport}
            />
          </Paper>
        </Grid>
      </Fade>
    </Container>

  );
}

export default Csv;