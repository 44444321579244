import React, { useState } from 'react'
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Tooltip from '@material-ui/core/Tooltip';
import MultipleSelect from './ExportFilter/MultipleSelect'
import FromDate from './ExportFilter/FromDate'
import useColor from '../../context/themeContext';
import ResetFilter from './ExportFilter/ResetFilter'
import { SyncExport } from './Utilsfunctions'
import BorderTextField from '../StyledComponent/BorderTextField'
import { borderRadius } from '../../classes'
import moment from 'moment'
import 'moment/locale/it';
moment.locale('it')


const SubscriptionExport = (props) => {
    
    const { COLORS } = useColor()
    const useStyles = makeStyles((theme) => ({
        tooltip: {
            fontSize: "28px",
        },
        root: {
            display: 'flex',
            alignItems: 'center',
        },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
        },
        buttonSuccess: {
            backgroundColor: COLORS.primary,
            '&:hover': {
                backgroundColor: COLORS.primary,
            },
        },
        buttonProgress: {
            color: "#fff",
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        button: {
            border: "2px solid" + COLORS.primary,
            height: "30px",
            color: "#0000008A",
            '&.Mui-disabled': {
                border: "2px solid" + COLORS.primary + "50",
            }
        },
        input: {
            padding: "12px 20px"
        }
    }));


    const CustomTooltip = withStyles({
        tooltip: {
            fontSize: "14px",
            backgroundColor: COLORS.primary,
            color: "#fff",
            textAlign: "center",
            borderRadius: "10px",
            fontWeight: "bolder"
        }
    })(Tooltip);
    const classes = useStyles();

    const [input, setInput] = useState({});
    const [survey, setSurvey] = useState("");
    const [completeSurvey, setCompleteSurvey] = useState(false);

    const handleChangeDate = (date) => {
        setInput({ ...input, "from_date": date })
    }
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        if (Array.isArray(value)) {
            if (!value?.find(el => el === "ALL")) {
                setInput({ ...input, [name]: value })
            } else {
                return;
            }
        } else {
            setInput({ ...input, [name]: value })
        }
    }

    const resetChange = (name) => {
        setInput({ ...input, [name]: [] })
    }

    function cleanIds(array) {
        if (Array.isArray(array)) {
            return array.map(a => a.id);
        } else {
            return array;
        }
    }

    const exportSurvey = async () => {
        props.setLoadingSurvey(true);
        let data;
        if (props.userMe?.role?.tier === "BO_COM") {
            data = {
                annotation: input.annotation,
                audit_filters: {
                    "filters": []
                },
                filters: {
                    "groups_ids": cleanIds(input.groups_ids),
                    "courses_ids": cleanIds(input.courses_ids),
                    "surveys_ids": cleanIds(input.surveys_ids),
                    "roles_ids": cleanIds(input.roles_ids),
                    "from_date": input?.from_date
                }
            }
        } else {
            data = {
                annotation: input.annotation,
                audit_filters: {
                    "filters": []
                },
                filters: {
                    "groups_ids": [2],
                    "courses_ids": cleanIds(input.courses_ids),
                    "surveys_ids": cleanIds(input.surveys_ids),
                    "from_date": input?.from_date
                }
            }
        }

        if (input?.groups_ids?.length > 0) {
            let groups_name = "";
            for (let i in input?.groups_ids) {
                const g = props.groups.find(group => group.id === input?.groups_ids[i].id);
                groups_name += g.name + ", "
            }
            data.audit_filters.filters.push({ "value": groups_name, "label": "Filtrato per gruppi: " })
        }
        if (input?.roles_ids?.length > 0) {
            let roles_name = "";
            for (let i in input?.roles_ids) {
                const r = props.roles.find(role => role.id === input?.roles_ids[i].id);
                roles_name += r.name + ", "
            }
            data.audit_filters.filters.push({ "value": roles_name, "label": "Filtrato per ruoli: " })
        }
        if (input?.courses_ids?.length > 0) {
            let courses_name = "";
            for (let i in input?.courses_ids) {
                const c = props.courses.find(course => course.id === input?.courses_ids[i].id);
                courses_name += c.name + ", "
            }
            data.audit_filters.filters.push({ "value": courses_name, "label": "Filtrato per corsi: " })
        }
        if (input?.surveys_ids?.length > 0) {
            let surveys_name = "";
            for (let i in input?.surveys_ids) {
                const s = props.surveys.find(survey => survey.id === input?.surveys_ids[i].id);
                surveys_name += s.name + ", "
            }
            data.audit_filters.filters.push({ "value": surveys_name, "label": "Filtrato per sondaggi: " })
        }




        clean(data.filters);

        await SyncExport(`/custom/v1/exports/survey-registries/`, data,
            setSurvey, props.setCustomAlert, setCompleteSurvey, props.setLoadingSurvey, props.setReloadSub);

    }


    function clean(obj) {
        for (let propName in obj) {
            if (obj[propName] === "NO" || obj[propName] === "" || obj[propName] === undefined) {
                delete obj[propName];
            }
        }
    }

    const disableSurveyDate = () => {
        setInput({ ...input, "from_date": "" })
    }

    return (
        <Paper elevation={2} style={{ minHeight: "100%", borderRadius: borderRadius, padding: "50px 20px" }}>
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <p style={{ color: COLORS.primary, paddingLeft: "20px" }} className={"label-attivita"}>Report sondaggi</p>

                <InputLabel style={{ padding: "20px", margin: "10px auto 15px", fontSize: "20px" }}>
                    Scarica il report aggiornato dei sondaggi
                </InputLabel>

                <Grid spacing={3} item container style={{ marginBottom: "20px" }}>

                    <ResetFilter
                        setInput={setInput}
                        input={input}
                    />

                    <Grid item xs={12} md={12} xl={12} style={{ textAlign: "center" }}>
                        <InputLabel style={{ margin: "0 auto", fontSize: "17px" }}>
                            Etichetta export:
                        </InputLabel>
                        <BorderTextField
                            variant="outlined"
                            name="annotation"
                            onChange={handleChange}
                            value={input.annotation || ""}
                            inputProps={{ className: classes.input }}
                        />
                    </Grid>

                    <MultipleSelect
                        name={"surveys_ids"}
                        label={"Scegli sondaggi:"}
                        all_label={"Tutti i sondaggi"}
                        data={props.surveys}
                        handleChange={handleChange}
                        resetChange={resetChange}
                        input={input?.surveys_ids}
                    />

                    <FromDate
                        export
                        label={"Genera dal:"}
                        value={input.from_date}
                        handleChange={handleChangeDate}
                        disableDate={disableSurveyDate}
                    />

                    <MultipleSelect
                        name={"courses_ids"}
                        label={"Filtra per corsi:"}
                        all_label={"Tutti i corsi"}
                        data={props.courses}
                        handleChange={handleChange}
                        resetChange={resetChange}
                        input={input?.courses_ids}
                    />

                    {props.userMe?.role?.tier === "BO_COM" ?
                        <React.Fragment>
                            <MultipleSelect
                                name={"groups_ids"}
                                label={"Filtra per gruppi:"}
                                all_label={"Tutti i gruppi"}
                                data={props.groups}
                                handleChange={handleChange}
                                resetChange={resetChange}
                                input={input?.groups_ids}
                            />


                            <MultipleSelect
                                name={"roles_ids"}
                                label={"Filtra per ruolo:"}
                                all_label={"Tutti i ruoli"}
                                data={props.roles}
                                handleChange={handleChange}
                                resetChange={resetChange}
                                input={input?.roles_ids}
                            />
                        </React.Fragment>
                        : null}

                </Grid>

                <div className={classes.wrapper}>
                    {!completeSurvey ?
                        <Button
                            style={{ padding: "10px 5px", borderRadius: "20px", background: COLORS.primary, width: "200px" }}
                            variant="contained"
                            color="primary"
                            disabled={(!props.loadingSurvey && !props.loadingSub && !props.loadingSession) ? false : true}
                            onClick={exportSurvey}
                        >
                            Genera CSV
                        </Button>
                        :
                        <ButtonGroup color="primary" style={{ borderRadius: "20px" }} variant="contained" >
                            <Button
                                style={{ padding: "10px 15px", borderTopLeftRadius: "20px", borderBottomLeftRadius: "20px", background: "#3aac4d" }}
                                startIcon={<GetAppIcon />}
                                disabled={(props.loadingSub || props.loadingSurvey) || props.loadingSession}
                                onClick={() => {
                                    setCompleteSurvey(false);
                                }}
                                download
                                href={survey}
                            >
                                Scarica CSV
                            </Button>
                            <Button
                                style={{ padding: "10px 15px", borderTopRightRadius: "20px", borderBottomRightRadius: "20px", background: COLORS.primary }}
                                onClick={() => {
                                    setCompleteSurvey(false);
                                }}
                            >
                                Annulla
                            </Button>
                        </ButtonGroup>
                    }
                    {(props.loadingSub || props.loadingSession) &&
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <CustomTooltip className={classes.tooltip} title="Attendere il completamento delle altre operazioni per utilizzare il servizio" placement="top-end">
                                <InfoIcon style={{ marginLeft: "10px", color: COLORS.primary }} />
                            </CustomTooltip>
                        </div>
                    }
                    {props.loadingSurvey && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
                {props.loadingSurvey && <p style={{ paddingTop: "15px", color: COLORS.primary, fontSize: "15px", textAlign: "center" }}>Si prega di attendere, l'operazione potrebbe richiedere alcuni minuti</p>}
            </div>
        </Paper>
    )
}

export default SubscriptionExport;