import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import helpers from "./helpers"
import socketClient from "../../services/socketClient"
import CustomControls from "./CustomControls"
import screenfull from "screenfull";
import ReactPlayer from 'react-player'
import { Button } from '@material-ui/core'
import useColor from '../../context/themeContext'



const TrackableVideo = (props) => {
    const { COLORS } = useColor()
    const { is_preview, handleResize } = props
    
    const mousePointerTimeout = React.useRef(null)
    const reactPlayer = React.useRef()
    const reactPlayerContainer = React.useRef()

    const [disableOverlay, setDisableOverlay] = React.useState(false)
    const [hidePointer, setHidePointer] = React.useState(false)
    const [socket, setSocket] = React.useState(null)
    const [isPlaying, setIsPlaying] = React.useState(props.hasAutoplay)
    const [isAutoplay, setIsAutoplay] = React.useState(props.hasAutoplay)
    const [isStarting, setIsStarting] = React.useState(true)
    const [isReady, setIsReady] = React.useState(false)
    const [isHidden, setIsHidden] = React.useState(true)
    const [videoControls, setVideoControls] = React.useState({
        "muted": false,
        "volume": 0.5,
        "played": 0,
        "seeking": false
    })

    // viene richiamata quando il video viene messo in pausa
    const handlePause = () => {
        helpers.setCookie("play", false)
        setIsPlaying(false)
        props.onPause()
        if (!is_preview) {
            const isVideoEnded = Math.round(reactPlayer.current?.getDuration()) === Math.round(reactPlayer.current?.getCurrentTime())
            if (!isVideoEnded && socket?.readyState === 1) {
                socketClient.sendStopMedia(socket, reactPlayer.current?.getCurrentTime())
            }
        }
    }

    // viene richiamata quando il video viene messo in play
    const handlePlay = () => {
        if (!is_preview) {
            helpers.setCookie("play", true)
            setIsPlaying(true)
            props.onPlay()
            if (socket?.readyState === 1 && !isAutoplay) {
                socketClient.sendPlayMedia(socket)
            } else {
                setIsAutoplay(false)
            }
        }else{
            helpers.setCookie("play", true)
            setIsPlaying(true)
            props.onPlay()
        }
    }

    // viene richiamata quando il player è pronto
    const handleReady = () => {
        setIsReady(true)
        if (props.currentTime && isStarting && reactPlayer?.current) {
            reactPlayer.current.seekTo(props.currentTime)
            setIsStarting(false)
        }
        handleResize()
    }

    // viene richiamata quando finisce un video
    const handleEnded = () => {
        if (!is_preview) {
            socketClient.sendStopMedia(socket, reactPlayer.current?.getCurrentTime())
                .finally(async () => {
                    if (!props.section.is_passed) {
                        await socketClient.sendEndSection(socket, reactPlayer.current?.getCurrentTime())
                    }
                    await socketClient.asyncDisconnect(socket, props.setReloadComplete)
                        .then(res => {
                            props.onEnd()
                        })
                })
        }
    }

    async function initiateSocket(){
        await helpers.buildSessionSocket(props.section.id, handleCloseConnection,() => false)
        .then(res => {
            setSocket(res)
            props.onStart()
            if (isAutoplay) {
                setDisableOverlay(true)
                socketClient.sendPlayMedia(res)
            }
            setIsReady(true)
        })
    }

    async function checkSocketAndSendMessage(callPlay = ()=>{}){
        if(!is_preview){
            if(socket?.readyState != 1){
                initiateSocket().then(()=>{
                    ////RECALL PLAY
                    callPlay()
                })
            }else{
                callPlay()
            }
        }else{
            callPlay()
        }
    }

    function handleCloseConnection(){
        setIsPlaying(false)
        if(socket?.readyState == 1){
            socketClient.asyncDisconnect(socket)
        }
    }


    // useEffect che viene richiamato quando cambia la section (props.section)
    React.useEffect(() => {
        setIsStarting(true)

        // Gestisce l'evento sull'uscita del focus dalla pagina
        const handleBlur = () => {
            if (props.section) {
                handlePause()
            }
        }


        // Gestisce l'evento sulla chiusura della pagina
        window.onbeforeunload = () => {
            if (!is_preview) {
                const isPlayingVideo = JSON.parse(helpers.readCookie("play"))
                if (isPlayingVideo) {
                    socketClient.sendStopMedia(socket, reactPlayer.current.getCurrentTime())
                        .then(res => socketClient.asyncDisconnect(socket))
                } else {
                    socketClient.asyncDisconnect(socket)
                }
            }
        }
        window.addEventListener("blur", handleBlur);
        if (props.section) {
            setIsReady(false)
            if (socket?.readyState === 1 && !is_preview) {
                socketClient.asyncDisconnect(socket, props.setReloadComplete)
            }
            if(!is_preview){
                initiateSocket()
            }
        }
        return () => {
            window.removeEventListener("blur", handleBlur);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.section])

    // useEffect che viene richiamato al cambio di stato del socket
    React.useEffect(() => {

        return () => {
            if (socket?.readyState === 1 && !is_preview) {
                let isPlayingVideo = false
                const currentTime = reactPlayer.current.getCurrentTime()
                if (helpers.readCookie("play")) {
                    isPlayingVideo = JSON.parse(helpers.readCookie("play"))
                }
                if (isPlayingVideo) {
                    socketClient.sendStopMedia(socket, currentTime)
                        .finally(() => socketClient.asyncDisconnect(socket, props.setReloadComplete))
                } else {
                    socketClient.sendPlayMedia(socket)
                        .then(res => socketClient.sendStopMedia(socket, currentTime))
                        .finally(() => socketClient.asyncDisconnect(socket, props.setReloadComplete))
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket])

    const duration = reactPlayer?.current ? reactPlayer.current.getDuration() : "00:00";
    const currentTime = reactPlayer?.current ? reactPlayer.current.getCurrentTime() : "00:00";

    const clearMouseTimeout = () => {
        if(mousePointerTimeout.current){
            clearTimeout(mousePointerTimeout.current)
            mousePointerTimeout.current = null
        }
    }

    const setMouseTimeout = () => { 
        if(!mousePointerTimeout.current)
        {
            mousePointerTimeout.current = setTimeout(
                mouseMovementManagement, 
                2200
            )
        }
    }       

    const mouseMovementManagement=()=>{
        ///nasconde il puntatore del mouse e la barra del player dopo 2 sec di inattività
        setIsHidden(true)
        setHidePointer(true)
    }

    const handleMouseMove = (e) => {
        e.stopPropagation()
        clearMouseTimeout()
        setMouseTimeout()
        setIsHidden(false)
        setHidePointer(false)
    }
    // viene richiamata quando si esce con il mouse dal player e vengono nascosti i controlli
    const handleMouseLeave = (e) => {
        e.stopPropagation()
        clearMouseTimeout()
        setIsHidden(true)
        setHidePointer(false)
    }

    // viene richiamata quando si passa con il mouse sul player e vengono mostrati i controlli
    const handleMouseEnter = (e) => {
        e.stopPropagation()
        setIsHidden(false)
        setHidePointer(false)
        clearMouseTimeout()
        setMouseTimeout()
    }

    // viene richiamata quando si passa da schermo intero e viceversa
    const handleToggleFullScreen = (e) => {
        e.stopPropagation();
        screenfull.toggle(reactPlayerContainer.current);
    };

    const handlePlayPause = (e) => {
        e.stopPropagation();
        checkSocketAndSendMessage( () => {
            setDisableOverlay(true)
            helpers.setCookie("play", !isPlaying)
            setIsPlaying(!isPlaying)
            
        })
    }

    const handleMute = (e) => {
        e.stopPropagation();
        setVideoControls({ ...videoControls, "muted": !videoControls.muted });
    }

    const handleVolumeChange = (e, newValue) => {
        e.stopPropagation();
        setVideoControls({
            ...videoControls,
            "volume": parseFloat(newValue / 100),
            "muted": newValue === 0 ? true : false,
        });
    };

    const handleSeekChange = (e, newValue) => {
        if (socket?.readyState === 1){
            setVideoControls({ ...videoControls, played: parseFloat(newValue / 100) });
        }
    };

    const handleSeekMouseDown = (e) => {
        setVideoControls({ ...videoControls, seeking: true });
    };

    const handleSeekMouseUp = (e, newValue) => {
        setVideoControls({ ...videoControls, seeking: false });
        reactPlayer.current.seekTo(newValue / 100, "fraction");
    }

    const handleProgress = (changeState) => {
        if (!videoControls.seeking) {
            setVideoControls({ ...videoControls, ...changeState });
        }
    };

    const restartVideo = (e) => {
        if (socket?.readyState === 1 || is_preview){
            reactPlayer.current.seekTo(0, "fraction")
            handlePlayPause(e)
        }
    }

    const CustomizableVideo = (
        <section 
        style={ hidePointer ? {cursor: "none"} : {} }
        onClick={handlePlayPause} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave} 
        onMouseEnter={handleMouseEnter} ref={reactPlayerContainer} 
        className={"video video_related"}>
            <ReactPlayer
                ref={reactPlayer}
                url={props.src || ""}
                playing={isPlaying}
                muted={videoControls.muted}
                volume={videoControls.volume}
                width="100%"
                height="100%"
                onPlay={handlePlay}
                onPause={handlePause}
                onEnded={handleEnded}
                onReady={handleReady}
                onProgress={handleProgress}
            />

            <CustomControls
                duration={duration}
                currentTime={currentTime}
                hidden={isReady ? isHidden : true}
                onToggleFullScreen={handleToggleFullScreen}
                onPlayPause={handlePlayPause}
                playing={isPlaying}
                muted={videoControls.muted}
                volume={videoControls.volume}
                onMute={handleMute}
                onVolumeChange={handleVolumeChange}
                played={videoControls.played}
                onSeek={handleSeekChange}
                onSeekMouseDown={handleSeekMouseDown}
                onSeekMouseUp={handleSeekMouseUp}
                is_passed={props.section?.is_passed}
                is_mandatory={props.section?.is_mandatory}
                is_preview={is_preview}
            />
        </section>
    )

    const ClassicVideo = (
        <section className={"video"}>
            <ReactPlayer
                config={{
                    youtube: { playerVars: { disablekb: 1 } },
                    vimeo: { playerOptions: { loop: false } },
                    file: { attributes: { controlsList: 'nodownload', disablePictureInPicture: true } }
                }}
                pip={false}
                ref={reactPlayer}
                url={props.src || ""}
                playing={isPlaying}
                controls
                volume={0.5}
                width="100%"
                height="100%"
                onPlay={handlePlay}
                onPause={handlePause}
                onEnded={handleEnded}
                onReady={handleReady}
            />
        </section>
    )

    const isYt = props.section?.media_file?.type === "YT"
    const section_started = Number(props.section?.step_time) == "0.0" ? false : true;
    
    const btnStyle = {
        color: "#ffffff",
        backgroundColor: COLORS.primary
    }
    
    return (
        <div key={props.src} className="container_video" onContextMenu={e => e.preventDefault()}>
            {isYt ? ClassicVideo : CustomizableVideo}

            { !isPlaying &&
            <div className="btn-overlay">
                {(!section_started && !disableOverlay) ? 
                <Button style={btnStyle} className="start-overlay" onClick={handlePlayPause}>
                    INIZIA A GUARDARE
                </Button>
                :
                <>
                    <Button style={btnStyle} className="resume-overlay" onClick={handlePlayPause}>
                        RIPRENDI
                    </Button>
                    <Button style={btnStyle} className="restart-overlay" onClick={restartVideo}>
                        RICOMINCIA
                    </Button>
                </>
                }
            </div>
            }
        </div>
    )
}

TrackableVideo.propTypes = {
    onStart: PropTypes.func,
    onEnd: PropTypes.func,
    onPlay: PropTypes.func,
    onPause: PropTypes.func,
    hasAutoplay: PropTypes.bool,
    src: PropTypes.string,
    currentTime: PropTypes.number,
    section: PropTypes.object,
}

export default TrackableVideo