import React, { useEffect, useState, useRef, Fragment } from 'react';
import Paper from '@material-ui/core/Paper'
import services from '../../services/services'
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment'
import 'moment/locale/it';
import useColor from '../../context/themeContext';
moment.locale('it')


const VideoStart = (props) => {

    const { is_preview } = props;
    
    const { COLORS } = useColor()
    const BlueTooltip = withStyles({
        tooltip: {
            fontSize: "14px",
            color: "#fff",
            backgroundColor: COLORS.primary
        }
    })(Tooltip);

    const isMount = useRef(true)
    const [lesson, setLesson] = useState(null);
    const [first, setFirst] = useState(false);


    const startVideo = () => {
        props.setLesson(lesson, true);
    }

    const restartCourse = () => {
        if(!is_preview){
            services.getService(`v1/users/me/subscriptions/${props.sub_id}/restart/`)
                .then(res => {
                    if (isMount.current) {
                        if (!res.data.meta.error) {
                            props.setLesson(res.data.data, true)
                        }
                    }
                }).catch(err => { console.log(err) })
        }
    }

    const startCourse = () => {
       
        const url = is_preview ? 
        `v1/preview/course/${props.id}/first-section/`
        :
        `v1/users/me/subscriptions/${props.sub_id}/first-section/`;

        services.getService(url)
        .then(res => {
            if (isMount.current) {
                props.setLesson(res.data.data, true)
            }
        }).catch(err => { console.log(err) })
        
    }

    useEffect(() => {
        if(!is_preview){
            services.getService(`v1/users/me/subscriptions/${props.sub_id}/last-section/`)
                .then(res => {
                    if (isMount.current) {
                        if (res.data.data.category !== "SURVEY") {
                            setLesson(res.data.data)
                           
                        }
                        setFirst(true)
                    }
                }).catch(err => {
                    setFirst(false)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.access])

    useEffect(() => { return () => isMount.current = false }, [])

    const returnDate = () => {
        if ((props.course_expiration_date && props.sub_expiration_date) && (props.course_expiration_date < props.sub_expiration_date)) {
            return "L'iscrizione al corso scade in data: " + moment(props?.course_expiration_date).format('DD/MM/YYYY');
        } else if ((props.course_expiration_date && props.sub_expiration_date) && (props.sub_expiration_date < props.course_expiration_date)) {
            return "L'iscrizione al corso scade in data: " + moment(props.sub_expiration_date).format('DD/MM/YYYY');
        } else if (props.course_expiration_date) {
            return "L'iscrizione al corso scade in data: " + moment(props.course_expiration_date).format('DD/MM/YYYY');
        } else if (props.sub_expiration_date) {
            return "L'iscrizione al corso scade in data: " + moment(props.sub_expiration_date).format('DD/MM/YYYY');
        } else {
            return false;
        }
    }

    const expiredMessageCourse = props.has_expired && props.course_expiration_date < props.sub_expiration_date ?
        "L'iscrizione al corso è scaduta in data: " + moment(props.course_expiration_date || props.sub_expiration_date).format("DD/MM/YYYY") :
        "L'iscrizione al corso è scaduta in data: " + moment(props.sub_expiration_date || props.course_expiration_date).format("DD/MM/YYYY");

    return (

        <React.Fragment>
            {props.sections !== 0 ?
                <Paper className="videoStart" style={{ borderRadius: "0" }}>
                    {props.has_expired || (props.sub_expired && props.has_blocking_subscription) ? null :

                        <h4 style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                            {(props?.course_expiration_date || props?.sub_expiration_date) && !props.has_expired && !props.sub_expired && props.show_expiration ?

                                <BlueTooltip placement={"top"} TransitionComponent={Zoom}
                                    title={`${returnDate()}`}>
                                    <InfoIcon style={{ fontSize: 30, color: "#167ac6", padding: "2px" }} />
                                </BlueTooltip>

                                :
                                (
                                    props.sub_expiration_date ?
                                        <BlueTooltip placement={"top"} TransitionComponent={Zoom}
                                            title={"L'iscrizione al corso è scaduta in data: " + moment(props.sub_expiration_date).format("DD/MM/YYYY")}>
                                            <InfoIcon style={{ fontSize: 30, color: "#167ac6", padding: "2px" }} />
                                        </BlueTooltip>
                                        : null
                                )
                            }
                        {      
                        is_preview ? 
                        (<h4>ATTENZIONE<br/>In modalità anteprima non è abilitato il tracciamento delle lezioni e lo stato delle iscrizioni non sarà salvato</h4>) : 
                    
                        lesson?.name ? ("Ecco il punto in cui eri rimasto: " + lesson.name) : null
                        }
                        </h4>

                    }
                    
                        <div>
                            {props.show_expiration && (props.has_expired || (props.sub_expired && props.has_blocking_subscription)) ?

                                <h4 style={{ display: "flex", justifyContent: "center", color: "red" }}>
                                    {expiredMessageCourse}
                                </h4>
                                :


                                <Fragment>
                                {props.sections > 0 ?
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    {first ?
                                        <React.Fragment>
                                            <button onClick={startVideo} className="btn_blue" style={{ background: COLORS?.primary, marginRight: "10px" }}>Riprendi da dove avevi interrotto</button>
                                            <button onClick={restartCourse} className="btn_green" style={{ marginLeft: "10px" }}>Ricomincia il corso</button>
                                        </React.Fragment>
                                        : <button onClick={startCourse} className="btn_green" style={{ marginLeft: "10px" }}>Inizia ora la tua formazione</button>}
                                </div>
                                : null}
                                </Fragment>
                            }
                        </div>
                    
                </Paper>
                :
                <>
                {props.sections === 0 ?
                <Paper className="videoStart" style={{ borderRadius: "0" }}>
                    <h4 style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        Il corso selezionato non contiene lezioni
                </h4>
                
                </Paper>
                :
                null
                }
                </>
            }
        </React.Fragment>

    );
};

export default VideoStart;
