import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper'
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import AddCircleIcon from '@material-ui/icons/AddCircle';
import serviceAdmin from '../../services/serviceAdmin'
import DoneAllIcon from '@material-ui/icons/DoneAll';
import AntSwitch from '../Switch/AntSwitch'
import ModalMedia from '../Modal/ModalMedia'
import CircularProgression from '../Progress/CircularProgression'
import { modalClass } from '../../classes'
import useColor from '../../context/themeContext'
import useAlert from '../../hooks/useAlert'
import FullTextField from '../StyledComponent/FullTextField'

export default function ModalLesson(props) {
  
  const [options, setAlert] = useAlert()
  const { COLORS } = useColor();
  const useStyles = makeStyles((theme) => (modalClass(COLORS)));
  const classes = useStyles();

  const inputImageRef = useRef()
  const isMount = useRef(true);

  const [open, setOpen] = useState(false)
  const [mediaLoad, setMediaLoad] = useState(false)
  const [input, setInput] = useState({})
  const [module_id, setModule] = useState()
  const [teachers, setTeachers] = useState([])
  const [surveys, setSurveys] = useState([])
  const [evaluations, setEvaluations] = useState([])
  const [image, setImage] = useState()
  const [timeUpload, setTimeUpload] = useState(null)
  const [timeVideo, setTimeVideo] = useState(null)
  const [checked, setChecked] = useState(false)
  const [checkedActive, setCheckedActive] = useState(false)
  const [mandatorySections, setMandatorySections] = useState([])
  const [conflict, setConflict] = useState(false)

  const setopen = (input) => { setOpen(input) }
  const setmediaload = (input) => { setMediaLoad(input) }

  const handleCloseMedia = () => { setOpen(false) }

  const setchecked = () => {
    setChecked(!checked)
  }
  const setcheckedactive = () => {
    setCheckedActive(!checkedActive)
  }

  const opt = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total)
      setTimeUpload(`Upload immagine ${percent}%`);
    }
  }

  useEffect(() => {
    setInput({ ...input, "module_id": props.module_id })
    setModule(props.module_id)

    serviceAdmin.getService(`v1/courses/${props.course_id}/course-sections/`)
      .then(res => {
        if (res.data.data) {
          setMandatorySections(res.data.data);
        }
      }).catch(err => {
        console.log(err)
      })

    if (teachers.length === 0) {
      serviceAdmin.getService('v1/teachers/')
        .then(res => {
          if (isMount.current) {
            setTeachers(res.data.data)
          }
        }).catch(err => {
          console.log(err)
        })
    }

    serviceAdmin.getService('v1/surveys/')
      .then(res => {
        if (isMount.current) {
          setSurveys(res.data.data)
        }
      }).catch(err => {
        console.log(err)
      })

    serviceAdmin.getService('v1/evaluations/')
      .then(res => {
        if (isMount.current) {
          setEvaluations(res.data.data)
        }
      }).catch(err => {
        console.log(err)
      })

    return () => {
      isMount.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open])

  const handleClose = () => {
    setImage(null);
    setInput({});
    props.setOpen(false);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInput({ ...input, [name]: value })
  }

  function clean(obj) {
    for (let propName in obj) {
      if (obj[propName] === "" || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }


  const optionsVideo = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total)
      setTimeVideo(percent);
    }
  }

  const createLesson = () => {
    const params = {
      ...input,
      "course_id": props.course_id,
      "thumbnail": image,
      "is_active": checkedActive,
      "is_mandatory": checked
    }
    clean(params)

    serviceAdmin.postService(`v1/course-modules/${module_id}/sections/`, params, opt)
      .then(res => {
        if (res.status === 201) {
          if (mediaLoad) {

            serviceAdmin.multipartService(`v1/course-sections/${res.data.data.id}/media/`, mediaLoad, optionsVideo)
              .then(res => {
                if (res.status === 201) {
                  setAlert(true, "Lezione creata correttamente!", "success");
                  props.setReload();
                  handleClose();
                  setConflict(false)
                  setTimeVideo(null)
                  setTimeUpload(null)
                }
              }).catch(err => {
                console.log(err)
              })

          } else {
            setAlert(true, "Lezione creata correttamente!", "success");
            props.setReload();
            handleClose();
            setConflict(false)
            setTimeVideo(null)
            setTimeUpload(null)
          }
        }
      }).catch(err => {
        if (err?.status === 409 || err?.status === 400) {
          if (err.data.reason.includes("slug")) {
            setConflict(true)
          }
        } else {
          setConflict(false)
        }
      })
  }

  const setImageRef = () => {
    inputImageRef.current.click()
  }

  ////////IMAGE SELECT

  function imageSelected(event) {
    const isImage = file => file["type"].includes("image")
    let input = event.target
    if (input.files && input.files[0]) {
      if (isImage(input.files[0])) {
        let reader = new FileReader()
        reader.onload = e => {
          setImage(e.target.result)
        }
        reader.readAsDataURL(input.files[[0]])
      } else {
        alert('foto non caricata')
      }
    }
  }

  return (


    <Drawer
      anchor={"right"}
      open={props.open}
      onClose={handleClose}
    >

      <div className={"drawer-container"}>
        <div className={classes.closeicon + " title-drawer-container"}>
          <CloseIcon style={{ cursor: "pointer", height: "30px", width: "30px" }} onClick={handleClose} />
          <h2 className={classes.title}> Crea una nuova lezione </h2>
        </div>
        <div className={"drawer-content-container"} style={{ padding: "20px 30px" }}>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InputLabel className={classes.label}>
                Nome lezione*
              </InputLabel>
              <FullTextField name="name" value={input.name || ""} onChange={handleChange} placeholder="Nome della lezione" variant="outlined" inputProps={{ className: classes.input }} />
            </Grid>
            <Grid item xs={6}>
              <InputLabel className={classes.label}>
                Modulo*
                        </InputLabel>
              <Select name="module_id" value={input.module_id || ""} onChange={handleChange} variant="outlined" className={classes.select}>
                {
                  props.moduls.map((module, index) => {
                    return <MenuItem className={classes.selected} key={index} value={module.id}>{module.name}</MenuItem>
                  })
                }

              </Select>
            </Grid>
            <Grid item xs={6}>
              <InputLabel className={classes.label}>
                Insegnante
                        </InputLabel>
              <Select name="teacher_id" value={input.teacher_id || undefined} onChange={handleChange} variant="outlined" className={classes.select}>
                <MenuItem className={classes.selected}>Nessun insegnante</MenuItem>
                {
                  teachers.map((teacher, index) => {
                    return <MenuItem className={classes.selected} key={index} value={teacher.id}>{teacher.first_name} {teacher.last_name}</MenuItem>
                  })
                }

              </Select>
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.label}>
                Descrizione
                        </InputLabel>
              <FullTextField name="description" value={input.description || ""} onChange={handleChange} placeholder="Descrizione della lezione" multiline rows={3} variant="outlined" inputProps={{ className: classes.textarea }} />
            </Grid>

            <Grid item xs={6}>
              <InputLabel className={classes.label}>
                Sezione di accesso
                      </InputLabel>
              <Select name="mandatory_section_id" value={input.mandatory_section_id || null} onChange={handleChange} variant="outlined" className={classes.select}>
                <MenuItem className={classes.selected} value={null}>Nessuna lezione di accesso</MenuItem>
                {
                  mandatorySections.map((section, index) => {
                    return <MenuItem className={classes.selected} key={index} value={section.id}>{section.name}</MenuItem>
                  })
                }

              </Select>
            </Grid>

            <Grid item xs={6}>
              <InputLabel className={classes.label}>
                Durata
              </InputLabel>
              <FullTextField
                variant="outlined"
                type="time"
                name="duration"
                value={input.duration || "00:00:01"}
                onChange={handleChange}
                className={classes.textField}
                inputProps={{ className: classes.inputNumber }}
              />
            </Grid>

            <Grid item xs={6}>

              <InputLabel className={classes.label}>
                Thumbnail
              </InputLabel>
              <div onClick={setImageRef} style={{ display: "inline-flex", alignItems: "center", cursor: "pointer" }}>
                {!timeUpload ?
                  <React.Fragment>
                    <input ref={inputImageRef} style={{ visibility: "hidden", width: "1px" }} type="file" onChange={imageSelected} />
                    {image ? "Cambia" : "Aggiungi"} immagine
                        {!image ?
                      <AddCircleIcon className={classes.color} style={{ cursor: "pointer", paddingLeft: "10px", width: "40px", height: "40px" }} />
                      :
                      <DoneAllIcon style={{ paddingLeft: "10px", width: "40px", height: "40px" }} className={classes.color} />
                    }
                  </React.Fragment>
                  :
                  <p>
                    {timeUpload}
                  </p>
                }
              </div>

            </Grid>


            <Grid item xs={6} md={6}>
              <InputLabel className={classes.label}>
                Video / File
              </InputLabel>
              {!timeVideo ?
                <div onClick={() => { setopen(true) }} style={{ display: "inline-flex", alignItems: "center" }}>
                  Aggiungi media
                <AddCircleIcon className={classes.color} style={{ cursor: "pointer", paddingLeft: "10px", width: "40px", height: "40px" }} />
                </div>
                :
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CircularProgression timeUpload={timeVideo} />
                </div>
              }
            </Grid>


            <Grid item xs={3}>
              <InputLabel className={classes.label}>
                Obbligatoria
              </InputLabel>
              <AntSwitch
                checked={checked}
                setChecked={setchecked}
              />
            </Grid>

            <Grid item xs={3}>
              <InputLabel className={classes.label}>
                Attiva
              </InputLabel>
              <AntSwitch
                checked={checkedActive}
                setChecked={setcheckedactive}
              />
            </Grid>

            {input.category === "SURVEY" &&
              <Grid item xs={6}>
                <InputLabel className={classes.label}>
                  Sondaggio
              </InputLabel>
                <Select name="survey_id" value={input.survey_id || null} onChange={handleChange} variant="outlined" className={classes.select}>
                  <MenuItem className={classes.selected} value={null}>Nessun sondaggio</MenuItem>
                  {
                    surveys.map((survey, index) => {
                      return <MenuItem className={classes.selected} key={index} value={survey.id}>{survey.name}</MenuItem>
                    })
                  }

                </Select>
              </Grid>
            }
            {input.category === "EVALUATION" &&
              <Grid item xs={6}>
                <InputLabel className={classes.label}>
                  Verifiche
              </InputLabel>
                <Select name="evaluation_id" value={input.evaluation_id || null} onChange={handleChange} variant="outlined" className={classes.select}>
                  <MenuItem className={classes.selected} value={null}>Nessuna verifica</MenuItem>
                  {
                    evaluations.map((evaluation, index) => {
                      return <MenuItem className={classes.selected} key={index} value={evaluation.id}>{evaluation.name}</MenuItem>
                    })
                  }

                </Select>
              </Grid>
            }
            <Grid item xs={6}>
              <InputLabel className={classes.label}>
                Categoria lezione*
              </InputLabel>
              <Select name="category" value={input.category || null} onChange={handleChange} variant="outlined" className={classes.select}>
                <MenuItem className={classes.selected} value={"LESSON"}>Lezione</MenuItem>
                <MenuItem className={classes.selected} value={"SURVEY"}>Sondaggio</MenuItem>
                <MenuItem className={classes.selected} value={"EVALUATION"}>Verifica</MenuItem>
              </Select>
            </Grid>


          </Grid>

          <ModalMedia
            setMediaLoad={setmediaload}
            add
            handleClose={handleCloseMedia}
            setCustomAlert={props.setCustomAlert}
            module_id={props.module_id}
            open={open}
            setOpen={setopen}
          />

        </div>
        <Paper elevation={3} className={classes.save + " button-container"}>

          <Button onClick={handleClose} className={classes.btn_del}>Annulla</Button>
          <Button disabled={input.category && input.name && input.module_id ? false : true} onClick={createLesson} className={classes.btn_save}>Crea</Button>

        </Paper>
      </div>
    </Drawer>
  );
}