import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import EventIcon from '@material-ui/icons/Event';
import { Fade } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import useColor from '../../context/themeContext'
import moment from 'moment'
import 'moment/locale/it';
moment.locale('it')


const useStyles = makeStyles({

  media: {
    height: 180,
    position: "relative",
    backgroundColor: "#ccc",
  },
  media_op: {
    opacity: 0.4,
    height: 180,
    position: "relative",
    backgroundColor: "#ccc",
  },
  title: {
    textAlign: "center",
    fontSize: "20px",
    fontWeight: 600,
    color: "#043043"
  },
  subtitle: {
    textAlign: "left",
    color: "#888",
    fontSize: "16px",
    fontWeight: 400,
    textTransform: "uppercase",
  },
  paragraph: {
    textAlign: "left",
    color: "#888",
    fontSize: "13px",
    fontWeight: 400,
    textTransform: "uppercase",
  }
});


const CardCourse = (props) => {
  const { COLORS } = useColor()


  const BlueTooltip = withStyles({
    tooltip: {
      fontSize: "14px",
      color: "#fff",
      backgroundColor: COLORS.primary
    }
  })(Tooltip);

  const classes = useStyles();
  const route = "/corsi/:id";

  const tooltip = props.has_expired && props?.course_expiration < props?.expiration ?
    "L'iscrizione al corso è scaduta in data: " + moment(props?.course_expiration || props?.expiration).format('DD/MM/YYYY')
    :
    "L'iscrizione al corso è scaduta in data: " + moment(props?.expiration || props?.course_expiration).format('DD/MM/YYYY');

  const tooltip_expired = props?.course_expiration < props?.expiration ?
    "L'iscrizione al corso scade in data: " + moment(props?.course_expiration || props?.expiration).format('DD/MM/YYYY')
    :
    "L'iscrizione al corso scade in data: " + moment(props?.expiration || props?.course_expiration).format('DD/MM/YYYY');


  const additional_text = props.course?.course?.additional_text ? props.course?.course?.additional_text : "";

  return (
    <Fade in={props.title ? true : false}>
      <Paper className="cardsCourse">
        <BlueTooltip 
        placement={"top"} 
        TransitionComponent={Zoom} 
        title={additional_text}>
          <CardMedia
            className={classes.media}
            image={props?.url}
          >
            {props?.course_expiration || props?.expiration ?

              <BlueTooltip placement={"right"} TransitionComponent={Zoom}

                title={props.has_expired || props.course_has_expired ? tooltip : tooltip_expired}>

                <div style={{ display: "inline" }}>
                  <EventIcon style={{ backgroundColor: COLORS.primary, color: "#fff", padding: "2px" }} />
                </div>
              </BlueTooltip>
              :
              null
            }
            {props.is_passed ?
              <div style={{ fontSize: "12px", color: "#fff", background: COLORS.primary, padding: "5px", display: "inline", position: "absolute", top: "0px", right: "0px" }}>
                COMPLETATO
            </div>
              :
              null
            }
          </CardMedia>
        </BlueTooltip>
        <CardContent style={{ position: "relative", height: "170px" }}>
          <Typography gutterBottom variant="h6" component="h4" className={classes.subtitle}>
            {props.subtitle}
          </Typography>
          <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
            {props.title}
          </Typography>
          <Typography gutterBottom variant="h6" component="h4" className={classes.paragraph}>
            {props.paragraph}
          </Typography>
          <div className="course-btn-container">
            <Link to={{ pathname: route.replace(':id', props.id) }}>
              <button style={{ backgroundColor: COLORS.primary }} className="course-btn">
                <PlayArrowIcon fontSize="small" /><span>VAI AL CORSO</span>
              </button>
            </Link>
          </div>
        </CardContent>
      </Paper>
    </Fade>
  );
}

export default CardCourse