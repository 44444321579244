import React, { useState, useEffect, Fragment } from 'react';
import services from "../../services/services";
import { AccordionSummary, Accordion, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import 'moment/locale/it';
import useColor from '../../context/themeContext';
import Related from './Related';
moment.locale('it')



export default function RelatedModules(props) {

    const { COLORS } = useColor()
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const useStyles = makeStyles((theme) => ({
        button: {
            margin: "0 0 20px",
            backgroundColor: "#3aac4d",
            color: "#fff!important",
            width: "100%",
            '&:active': {
                backgroundColor: "#3aac4d",
                color: "#fff"
            },
            '&:hover': {
                backgroundColor: "#3aac4d",
            }
        },
        collapse_content: {
            flexDirection: "column",
            background: COLORS.primary,
            padding: "0 0",
            //opacity: .8
        },
        collapse_text: {
            backgroundColor: COLORS.primary,
            padding: " 0 20px",
            color: "#ffffff",
            fontWeight: "bold",
            bordeBbottom: "2px solid " + COLORS.primary,
            flexDirection: 'row-reverse'
        }
    }));
    const classes = useStyles();

    const { sub_id, id, is_preview } = props

    const [modules, setModules] = useState([])
    const [hide, sethide] = useState(true)
    const [expanded, setExpanded] = React.useState(0);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const setHide = () => { sethide(!hide) };


    useEffect(() => {

        if ((sub_id && id) || (is_preview && id)) {
            console.log("RELOAD Modules")
            let url = is_preview ?
                `v1/course-themes/${id}/modules/`
                :
                `v1/users/me/subscriptions/${sub_id}/course-themes/${id}/course-modules/`

            services.getService(url)
                .then(res => {

                    if (res.data.data) {
                        const modulesMapped = res.data.data.map(item => { return { ...item, openAccordion: true } })
                        setModules(modulesMapped);
                    }

                }).catch(err => {
                    console.log(err);

                })

        }
    }, [sub_id, id, props.reloadComplete])

    useEffect(() => {
        if (props.lessonCurrent) {
            const module = props.lessonCurrent?.module
            setExpanded(module?.id)
            props.setExpandedThematic(module?.course_theme?.id)
        }
    }, [props.lessonCurrent])

    const handleAccordion = (module) => {
        module.openAccordion = !module.openAccordion
        forceUpdate()
    }

    return (
        <Fragment>
            {modules.map((module, index) => {

                if (module.is_hidden) {
                    return (
                        <div key={index} style={{ width: "100%", backgroundColor: COLORS.primary }}>
                            <Related
                                {...props}
                                first={index}
                                id={module.id}
                                hide={hide}
                                setHide={setHide}
                                hiddenModule={module.is_hidden}
                            />
                            {index !== modules.length - 1 &&
                                <div style={{backgroundColor: "#6190ad", height: 1, width: "100%" }} />
                            }
                        </div>
                    )
                } else {
                    return (
                        <React.Fragment key={index}>
                            <Accordion expanded={module.openAccordion} onChange={() => handleAccordion(module)} key={index} style={{ width: "100%" }} className="collapse_modulo" >
                                <AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: "#ccc" }} />} IconButtonProps={{
                                    edge: "start",
                                    disableRipple: true
                                }} className={classes.collapse_text}>
                                    <h2>{module.name}</h2>
                                </AccordionSummary>
                                <AccordionDetails className={classes.collapse_content}>

                                    <Related
                                        {...props}
                                        first={index}
                                        id={module.id}
                                        hide={hide}
                                        setHide={setHide}
                                    />

                                </AccordionDetails>
                            </Accordion>
                            {index !== modules.length - 1 &&
                                <div style={{backgroundColor: "#6190ad", height: 1, width: "100%" }} />
                            }
                        </React.Fragment>
                    )
                }
            })
            }
        </Fragment>

    )
}