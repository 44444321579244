import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Table, TableContainer, TableBody, TableCell, TableHead, TableRow,
  TablePagination, IconButton, CircularProgress, Tooltip, Backdrop
} from '@material-ui/core/';
import useColor from '../../context/themeContext'
import GetAppIcon from '@material-ui/icons/GetApp';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CollapseRow from '../Row/CollapseRow'
import ModalFilterExport from '../Modal/ModalFilterExport';
import { tableStyle } from '../../classes'


const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "14px"
  }
})(Tooltip);


export default function TableImport(props) {
  const { COLORS } = useColor();
  const useStyles = makeStyles(tableStyle(COLORS));
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);

  const handleCloseModal = () => {
    setOpen(false);
  }

  useEffect(() => {
    if (props.imports.length >= 0) {
      setRows(props.imports)

    }
  }, [props.imports])



  const handleChangePage = (event, newPage) => {
    props.setPageImport(newPage + 1)
    props.setBackdrop(true);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsImport(parseInt(event.target.value, 10));
    props.setPage(0);
    props.setBackdrop(true);
  };


  return (
    <React.Fragment>

      <TableContainer className={classes.container}>
        <Backdrop style={{ zIndex: "2", position: "absolute", borderRadius: "20px", backgroundColor: "#fff", opacity: "0.4" }} open={props.backdrop}>
          <CircularProgress style={{ color: COLORS.primary }} />
        </Backdrop>
        <Table size={"small"} className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              {props.headcell.map((row, index) => (
                <TableCell key={index} className={classes.table_label+" "+row.class} align="center">{row.name}</TableCell>
              ))
              }
            </TableRow>
          </TableHead>
          <TableBody>

            {rows.map((row, index) => (
              props.detail ?
                <CollapseRow import={props.import} export={props.export} row={row} key={index} />

                :
                <TableRow hover key={index}>
                  <TableCell className={classes.label_cell} align="center">{row?.user}</TableCell>
                  <TableCell title={row.annotation} className={classes.label_cell} align="center">

                    {
                      ((row.annotation)?.length > 30) ?
                        (false ? row.annotation : ((row.annotation)?.substring(0, 30 - 3) + "...")) :
                        row.annotation
                    }

                  </TableCell>
                  <TableCell className={classes.label_cell+" hide-column-md"} align="center">{row?.created}</TableCell>

                  <TableCell className={classes.label_cell} align="center">
                    {row?.type === "user" && "Utenti"}
                    {row?.type === "subscription" && "Iscrizioni"}
                    {row?.type === "survey" && "Sondaggi"}
                    {row?.type === "course" && "Corsi"}
                  </TableCell>

                  <TableCell className={classes.label_cell+" hide-column-md"} align="center">
                    {row.success ? <CheckCircleIcon style={{ color: COLORS.primary, marginLeft: "15px" }} /> : <HighlightOffIcon style={{ color: COLORS.primary, marginLeft: "15px" }} />}
                  </TableCell>
                  {row.file ?
                    <TableCell className={classes.label_cell} align="center">
                      <CustomTooltip
                        title={props.import ? "Scarica il file di import" : "Scarica l'export"}
                        placement="top"
                      >
                        <IconButton
                          download
                          href={row?.file}
                          aria-label="download"
                          className={classes.margin}
                        >
                          <GetAppIcon />
                        </IconButton>
                      </CustomTooltip>
                    </TableCell>
                    :
                    <TableCell className={classes.label_cell} align="center">
                    </TableCell>
                  }

                  {/*<TableCell className={classes.label_cell} align="center">
                    <CustomTooltip
                      title={"Filtri utilizzati nell'export"}
                      placement="top"
                    >
                      <IconButton
                      onClick={()=>handleOpenModal(row)}
                      className={classes.margin}
                      >
                        <FilterListIcon />
                      </IconButton>
                    </CustomTooltip>
                  </TableCell>*/}
                </TableRow>

            ))}
            <ModalFilterExport
              row={{}}
              openModal={open}
              handleCloseModal={handleCloseModal}
            />
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={props.count}
        rowsPerPage={props.rowsImport}
        page={props.page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={"Righe per pagina: "}
        classes={{ menuItem: classes.selected }}
      />
    </React.Fragment>
  );
}
