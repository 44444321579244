import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core/'
import services from '../../services/services'
import useColor from '../../context/themeContext'


const NextButton = (props) => {

	const { COLORS } = useColor()

	const { category, setRestart, is_passed, left_tries, next, setLesson, module_id, lesson_id, retry, is_mandatory, is_propedeutic_to_others } = props

	const useStyles = makeStyles({
		button: {
			margin: "30px auto",
			fontWeight: "bold",
			backgroundColor: "#3fbe54!important",
			color: "#fff!important",
			'&:hover': {
				backgroundColor: "#3fbe54",
				color: "#fff"
			},
			'&:active': {
				backgroundColor: "#3fbe54!important",
				color: "#fff"
			}
		},
		button_blue: {
			margin: retry ? "0" : "30px auto",
			fontWeight: "bold",
			backgroundColor: COLORS.primary + "!important",
			color: "#fff!important",
			'&:hover': {
				backgroundColor: COLORS.primary,
				color: "#fff"
			},
			'&:active': {
				backgroundColor: COLORS.primary + "!important",
				color: "#fff"
			}
		},
		container_btn: {
			width: "100%",
			display: "flex",
			alignItems: "center",
			justifyContent: "space-beetween"
		}
	});
	const classes = useStyles();

	const [nextAccessible, setNextAccessible] = useState(false)
	const [nextLesson, setNextLesson] = useState({})

	const nextSection = () => {
		if (nextLesson) {
			setLesson(nextLesson)
		}
	}

	useEffect(() => {
		services.getService(`v1/users/me/course-modules/${module_id}/course-sections/${lesson_id}/next/`)
			.then(res => {
				if (res?.data?.data) {
					setNextAccessible(true)
					setNextLesson(res.data.data)
				}
			}).catch(err => {
				console.log(err)
				setNextAccessible(false)

			})
	}, [lesson_id])

	///RIPETE IL TEST
	const againTest = () => {
		setRestart();
	}

	const renderButton = () => {
		if (category === "EVALUATION") {
			if ((is_passed && is_mandatory) || (is_passed && is_propedeutic_to_others)) { // se la lezione è stata passata ed è obbligatoria/propedeutica
				return (
					<Button onClick={nextSection} className={classes.button}>
						Continua con le lezioni
					</Button>
				)
			} else if (left_tries > 0 && !nextAccessible) { // si può riprovare ma non ci sono lezioni successive
				return (
					<Button onClick={againTest} className={classes.button}>
						Riprova a svolgere la verifica
					</Button>
				)
			} else if (left_tries > 0 && nextAccessible) { // si può riprovare e ci sono lezioni successive
				return (
					<div className={classes.container_btn}>
						<Button onClick={againTest} className={classes.button_blue}>
							Riprova a svolgere la verifica
						</Button>

						<Button onClick={nextSection} className={classes.button}>
							Continua con le lezioni
						</Button>
					</div>
				)
			} else if (left_tries < 1 && nextAccessible) { // non si può riprovare ma ci sono lezioni successive
				return (
					<Button onClick={nextSection} className={classes.button}>
						Continua con le lezioni
					</Button>
				)
			} else { // se non si può riprovare e non ci sono lezioni successive
				return null
			}
		} else {
			if (next) {
				return (
					<Button onClick={nextSection} className={classes.button}>
						Continua con le lezioni
					</Button>
				)
			} else return null
		}
	}

	return (
		<div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
			{renderButton()}
		</div>
	)

}
export default NextButton;