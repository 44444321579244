import React, { useEffect, useState } from "react";
import { Backdrop, TableContainer, Typography, Table, TableBody, TableHead, TableRow, TableCell, IconButton, Collapse, Box, CircularProgress } from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import useColor from '../../context/themeContext'
import GetAppIcon from '@material-ui/icons/GetApp';
import serviceAdmin from "../../services/serviceAdmin";
import Skeleton from '@material-ui/lab/Skeleton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Tooltip from '@material-ui/core/Tooltip'
import DescriptionIcon from '@material-ui/icons/Description';
import RowModal from './RowModal'
import { tableCellFontSize } from "../../classes"

const CustomTooltip = withStyles({
    tooltip: {
        fontSize: "14px"
    }
})(Tooltip);

const CollapseRow = (props) => {
    
    const { COLORS } = useColor();
    const useStyles = makeStyles({
        root: {
            '& > *': {
                borderBottom: 'unset',
            },
        },
        table_label: {
            color: COLORS.primary + "!important",
            fontWeight: "bold!important",
            fontSize: "18px!important"
        },
        label_cell: {
            color: "#333!important",
            fontSize: tableCellFontSize + "!important"
        },
        menu_table: {
            color: COLORS.primary + "!important"
        },
        menu_table_del: {
            color: "red"
        },
        margin: {
            padding: "5px"
        },
        container: {
            maxHeight: "400px",
            position: "relative"
        },
        headcell: {
            fontWeight: "bold"
        },
        skeleton: {
            width: "100%"
        },
        loadtext: {
            color: COLORS.primary,
            fontWeight: "bold",
            cursor: "pointer"
        },
        back: {
            zIndex: "2",
            position: "absolute",
            borderRadius: "20px",
            backgroundColor: "#fff",
            opacity: "0.4"
        }
    });
    const classes = useStyles();

    const { row } = props;

    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [data, setData] = useState({})
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState([]);
    const [error, setError] = useState([]);
    const [loadData, setLoadData] = useState(false);

    useEffect(() => {

        if (open && props.import) {
            serviceAdmin.getService(`/v1/synchronization/batch-codes/${row.id}/`)
                .then(res => {
                    if (res.data.data) {
                        setInfo(res.data.data?.imports)
                        setError(res.data.data?.queue_operation_traceback)
                        setLoading(true)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        } else if (open && props.export) {
            serviceAdmin.getService(`/v1/synchronization/data-exports/${row.id}/`)
                .then(res => {
                    if (res.data.data) {
                        setInfo(res.data.data?.json_source)
                        setError(res.data.data?.queue_operation_traceback)
                        setLoading(true)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, props.import, props.export])

    const setCollapse = (e) => {
        setOpen(!open);
        e.stopPropagation();
    }

    const setModal = (value) => {
        setLoadData(true)
        serviceAdmin.getService(`/v1/synchronization/data-imports/${value?.id}/`)
            .then(res => {
                if (res?.status === 200) {
                    console.log(res.data.data)
                    setData(res?.data?.data)
                    setOpenModal(true)
                    setLoadData(false)
                }
            }).catch(err => {
                console.log(err);
                setLoadData(false)
            })
    }
    const handleCloseModal = () => {
        setOpenModal(false)
    }

    return (
        <React.Fragment>
            <TableRow style={open ? { backgroundColor: COLORS.primary + "30" } : {}} className={classes.root} hover>
                <TableCell className={classes.label_cell} align="center">
                    <IconButton
                        onClick={setCollapse}
                        aria-label="collapse"
                        className={classes.margin}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell className={classes.label_cell} align="center">{row?.user}</TableCell>
                <TableCell className={classes.label_cell+" hide-column-md"} align="center">{row?.created}</TableCell>
                <TableCell className={classes.label_cell} align="center">
                    {row.success ? <CheckCircleIcon style={{ color: COLORS.primary, marginLeft: "15px" }} /> : <HighlightOffIcon style={{ color: COLORS.primary, marginLeft: "15px" }} />}
                </TableCell>

                <TableCell className={classes.label_cell} align="center">
                    {row.file &&
                        <CustomTooltip placement="top" title={props.import ? "Download esito operazione" : ""}>
                            <IconButton
                                download
                                onClick={(e) => e.stopPropagation()}
                                href={row?.file}
                                aria-label="download"
                                className={classes.margin}
                            >
                                <GetAppIcon />
                            </IconButton>
                        </CustomTooltip>
                    }
                </TableCell>

            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>

                        {row.success &&
                            <Box margin={3}>
                                <Typography align="center" style={{ fontWeight: "bold", color: COLORS.primary, margin: "30px 0" }} variant="h6" gutterBottom component="div">
                                    Import effettuati
                    </Typography>
                                <TableContainer className={classes.container}>
                                    <Backdrop className={classes.back} open={loadData}>
                                        <CircularProgress style={{ color: COLORS.primary }} />
                                    </Backdrop>
                                    <Table stickyHeader aria-label="purchases">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.headcell} align={"left"}>Nome utente</TableCell>
                                                <TableCell className={classes.headcell+" hide-column-md"} align={"left"}>Nome</TableCell>
                                                <TableCell className={classes.headcell+" hide-column-md"} align={"left"}>Cognome</TableCell>
                                                <TableCell className={classes.headcell+" hide-column-md"} align={"left"}>Codice fiscale</TableCell>
                                                <TableCell className={classes.headcell} align={"left"}>Operazione</TableCell>
                                                <TableCell className={classes.headcell} align={"center"}>File di import</TableCell>
                                                <TableCell className={classes.headcell} align={"center"}>Dettaglio import</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        {loading ?
                                            <TableBody>
                                                {info?.map((row, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell className={classes.label_cell} align={"left"}>{row.user.username}</TableCell>
                                                            <TableCell className={classes.label_cell+" hide-column-md"} align={"left"}>{row.user.first_name}</TableCell>
                                                            <TableCell className={classes.label_cell+" hide-column-md"} align={"left"}>{row.user.last_name}</TableCell>
                                                            <TableCell className={classes.label_cell+" hide-column-md"} align={"left"}>{row.user.fiscal_code}</TableCell>
                                                            <TableCell className={classes.label_cell} align={"left"}>{row.additional_data?.type === "subscription" ? "Iscrizione" : "Creazione"}</TableCell>
                                                            <TableCell className={classes.label_cell} align="center">
                                                                <CustomTooltip
                                                                    title={"Download file di import"}
                                                                    placement="top"
                                                                >
                                                                    <IconButton
                                                                        download
                                                                        href={row?.file_source}
                                                                        aria-label="download"
                                                                        className={classes.margin}
                                                                    >
                                                                        <DescriptionIcon />
                                                                    </IconButton>
                                                                </CustomTooltip>
                                                            </TableCell>
                                                            <TableCell className={classes.label_cell} align="center">
                                                                <CustomTooltip
                                                                    title={row.additional_data?.type === "subscription" ? "Dettaglio delle iscrizioni generate" : "Dettaglio degli utenti generati"}
                                                                    placement="top"
                                                                >
                                                                    <span onClick={() => setModal(row)} className={classes.loadtext} >
                                                                        {row.additional_data?.type === "subscription" ? "Iscrizioni generate" : "Utenti generati"}
                                                                    </span>
                                                                </CustomTooltip>
                                                            </TableCell>

                                                        </TableRow>
                                                    )
                                                })}

                                            </TableBody>

                                            :

                                            <TableBody>
                                                {[1, 2, 3, 4, 5].map((i, index) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell align={"left"}>
                                                                <Skeleton className={classes.skeleton} />
                                                            </TableCell>
                                                            <TableCell align={"left"}>
                                                                <Skeleton className={classes.skeleton} />
                                                            </TableCell>
                                                            <TableCell align={"left"}>
                                                                <Skeleton className={classes.skeleton} />
                                                            </TableCell>
                                                            <TableCell align={"left"}>
                                                                <Skeleton className={classes.skeleton} />
                                                            </TableCell>
                                                            <TableCell align={"left"}>
                                                                <Skeleton className={classes.skeleton} />
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}

                                            </TableBody>
                                        }

                                    </Table>
                                </TableContainer>
                            </Box>
                        }
                        {!row.success &&
                            <Box margin={3}>
                                <Typography align="center" style={{ fontWeight: "bold", color: COLORS.primary, margin: "30px 0" }} variant="h6" gutterBottom component="div">
                                    Generazione non riuscita
                    </Typography>
                                {error &&
                                    <Typography align="center" style={{ margin: "30px 0", fontSize: "18px" }} variant="h6" gutterBottom component="div">
                                        Causa errore: {error}
                                    </Typography>
                                }
                            </Box>
                        }
                    </Collapse>
                </TableCell>
            </TableRow>

            <RowModal
                data={data}
                openModal={openModal}
                handleCloseModal={handleCloseModal}
            />

        </React.Fragment>
    );
}

export default CollapseRow;
