import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TableCourseUsers from '../Table/TableCourseUsers';
import { useEffect } from 'react';
import serviceAdmin from '../../services/serviceAdmin'
import useColor from '../../context/themeContext'
import ModalUserSubscription from '../Modal/ModalUserSubscription'
import SearchInput from '../Search/SearchInput';
import { SyncDetailExport } from '../Export/Utilsfunctions'
import { Select } from '@material-ui/core';
import SelectFilter from '../Select/SelectFilter';



const is_in_history_data = [
    { name: "Iscrizioni storiche", id: "True" },
    { name: "Iscrizioni non storiche", id: "False" },
]


const CourseUsers = (props) => {
    const { COLORS } = useColor();
    const useStyles = makeStyles({
        title: {
            color: "#4c23f1",
            fontSize: "20px",
            fontWeight: "bold",
            paddingBottom: "10px"
        },
        subtitle: {
            fontWeight: "bold",
            paddingBottom: "10px"
        },
        select: {
            width: "100%",
            maxWidth: "235px",
            margin: "30px 0"
        },
        color: {
            color: COLORS.primary + "!important"
        }

    });
    const classes = useStyles();

    const [is_in_history_filter, setIsInHistoryFilter] = useState(null)
    const [openModify, setOpenModify] = useState(false);
    const [open, setOpen] = useState(false)
    const [reload, setReload] = useState(false)
    const [confirm, setConfirm] = useState(false)
    const [subscription_id, setSubscription_id] = useState(null)
    const [search, setSearch] = useState("")
    const [all, setAll] = useState(false)
    const [selected, setSelected] = useState([])
    const [annotation, setAnnotation] = useState("")


    const setconfirm = (input, user) => {
        setConfirm(input)
        setSubscription_id(user.id)
    }

    const setreload = () => {
        setReload(true)
    }

    const setopen = () => {
        setOpen(true)
    }

    const setopenmodify = (input) => {
        setOpenModify(input)
    }

    const exportTable = (info, callback, setComplete) => {
        const params = {
            "filters": {},
            "export_fmt": info
        }
        if (annotation) params.annotation = annotation;
        if (selected.length > 0 && !all) {
            params.filters.subscriptions_ids = selected;
        }
        SyncDetailExport('/custom/v1/exports/subscriptions/', params, callback, setComplete, props.setAlertCsv)
    }

    useEffect(() => {
        let url = `v1/subscriptions/?course=${props.course.id}&page=${props.pageUser}&page_items=${props.rowsUser}`;
        if (search?.length > 0) url += `&search=${search}`;
        if(is_in_history_filter != null)
            url += `&is_in_history=${is_in_history_filter === "True"}`;

        serviceAdmin.getService(url)
            .then(res => {
                if (res.data) {
                    props.setCount(res.data.meta.pagination.items_count)
                    props.setUsers(res.data.data)
                    setReload(false)
                }
            }).catch(err => {
                console.log(err)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload, props.rowsUser, props.pageUser, search, is_in_history_filter])

    useEffect(() => {
        if (confirm && subscription_id) {
            serviceAdmin.deleteService(`v1/subscriptions/${subscription_id}/`)
                .then(res => {
                    if (res.status === 200) {
                        props.setCustomAlert(true, "success", "Iscrizione al corso rimossa correttamente!");
                        setReload(true);
                        setOpenModify(false);
                        setConfirm(false)
                        setSubscription_id(null)
                    }
                }).catch(err => {
                    console.log(err)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscription_id])


    const handleChangeHistoryFilter = (e) => {
        const value = e.target.value;
        if (value != "ALL") {
            setIsInHistoryFilter(value)
        }
        else {
            setIsInHistoryFilter(null)
        }

    }

    const resetChangeString = () => {
        setIsInHistoryFilter(null)
    }

    return (
        <div>

            <h2 style={{ marginBottom: "20px" }} className="title_corsi_admin">Utenti del corso <span className={classes.color}>{props.course.name}</span></h2>
            <SearchInput
                searchString={search}
                placeholder={"Cerca tra gli utenti..."}
                onChange={event => setSearch(event.target.value)}
                background
            >

                <SelectFilter
                    style={{marginLeft: 30, width: 200}}
                    name={"is_in_history_filter"}
                    label={"Filtra per iscrizioni:"}
                    all_label={"Tutte le iscrizioni"}
                    data={is_in_history_data}
                    handleChange={handleChangeHistoryFilter}
                    resetChange={resetChangeString}
                    input={is_in_history_filter}
                />

            </SearchInput>




            <div className={"subscription_course " + classes.color}>
                Aggiungi utenti al corso <AddCircleIcon onClick={setopen} className={"subscription_icon " + classes.color} />
               
            </div>
           
            <TableCourseUsers
                setAnnotation={setAnnotation}
                annotation={annotation}
                setSelected={setSelected}
                all={all}
                setAll={setAll}
                exportTable={exportTable}
                setCustomAlert={props.setCustomAlert}
                count={props.count}
                setPageUser={props.setPageUser}
                setRowsUser={props.setRowsUser}
                setConfirm={setconfirm}
                users={props.users}
                course_id={props.course.id}
                course={props.course}
                setReload={setreload}
                setOpenModify={setopenmodify}
                openModify={openModify}
            />

            <ModalUserSubscription
                setCustomAlert={props.setCustomAlert}
                open={open}
                setOpen={setOpen}
                course_id={props.course.id}
                setReload={setreload}
            />

        </div>
    )

}

export default CourseUsers;