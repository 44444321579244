import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Tabs, Tab, Breadcrumbs } from '@material-ui/core'
import TabModifyCourse from '../TabModifyCourse/TabModifyCourse'
import BookIcon from '@material-ui/icons/BookOutlined'
import Moduls from './Moduls'
import Teachers from './Teachers'
import AddGroup from './AddGroup'
import CourseUsers from './CourseUsers'
import serviceAdmin from '../../services/serviceAdmin'
import TeachingMaterial from './TeachingMaterial'
import Link from '@material-ui/core/Link'
import { tabsStyle } from '../../classes'
import CustomTooltip from '../StyledComponent/CustomTooltip'
import useColor from '../../context/themeContext'
import { useParams } from 'react-router-dom'
import Thematics from './Thematics'


function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div p={3}>
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function CenteredTabs(props) {
  const { COLORS } = useColor();
  const useStyles = makeStyles(tabsStyle(COLORS));
  const classes = useStyles();

  const id = getIdFromUrl()

  function getIdFromUrl() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    return params.course;
  }

  const [value, setValue] = useState(props.value);
  const [teachers, setTeachers] = useState([])
  const [users, setUsers] = useState([])
  const [pageUser, setPageUser] = useState(1)
  const [rowsUser, setRowsUser] = useState(5)
  const [count, setCount] = useState(Number)
  const [reload, setReload] = useState(true)
  const [modules, setModules] = useState([])
  const [show_modules, setShowModules] = useState(false)
  const [course_theme_id, setCourseThemeId] = useState(null)
  const [courseTheme, setCourseTheme] = useState(null)

  const setreload = () => { setReload(true) }
  const setpageuser = (input) => { setPageUser(input) }
  const setrowsuser = (input) => { setRowsUser(input) }
  const setcount = (input) => { setCount(input) }

  const setusers = (input) => {
    setUsers(input)
  }

  const setteachers = (input) => {
    setTeachers(input)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  useEffect(() => {
    if (props.course.id) {
      serviceAdmin.getService(`v1/teachers/?status=${props.course.id}`)
        .then(res => {
          setTeachers(res.data.data)
        }).catch(err => {
          console.log(err)
        })
      serviceAdmin.getService(`v1/subscriptions/?course=${props.course.id}&page=${pageUser}&page_items=${rowsUser}`)
        .then(res => {
          setUsers(res.data.data)
          setCount(res.data.meta.pagination.items_count)
        }).catch(err => {
          console.log(err)
        })
    }

    return () => {
      setValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.course.id])


  const closeTab = ()=>{
    props.handleClose();
  }

  return (
    <React.Fragment>
      <Paper className={classes.root}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href={"#"} onClick={closeTab} className={classes.link}>
            <BookIcon className={classes.icon} />
            <span className={classes.span}>Corsi</span>
          </Link>
          <Link color="inherit" className={classes.link_active}>
            <span className={classes.span}>
              {props.course?.name}
            </span>
          </Link>
        </Breadcrumbs>
        <Tabs
          className={classes.tabs}
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          scrollButtons="auto"
          variant="scrollable"
        >
          <CustomTooltip enterDelay={1000} title={"Qui puoi modificare i dati di un corso"} placement={"top"}>
            <Tab className={classes.tab} label="Dettaglio corso"      {...a11yProps(0)} />
          </CustomTooltip>
          <CustomTooltip enterDelay={1000} title={"Qui puoi aggiungere/modificare un modulo o una lezione"} placement={"top"}>
            <Tab className={classes.tab} label="Contenuti"      {...a11yProps(1)} />
          </CustomTooltip>
          <CustomTooltip enterDelay={1000} title={"Qui puoi iscrivere degli utenti al corso e vedere gli utenti già iscritti"} placement={"top"}>
            <Tab className={classes.tab} label="Iscrizioni" {...a11yProps(2)} />
          </CustomTooltip>
          <CustomTooltip enterDelay={1000} title={"Qui puoi aggiungere del materiale didattico che verrà poi mostrato nel corso"} placement={"top"}>
            <Tab className={classes.tab} label="Materiale" {...a11yProps(3)} />
          </CustomTooltip>
          <CustomTooltip enterDelay={1000} title={"Qui puoi aggiungere uno o più gruppi di utenti ad un corso"} placement={"top"}>
            <Tab className={classes.tab} label="Gruppi" {...a11yProps(4)} />
          </CustomTooltip>
        </Tabs>
      </Paper>
      <div className={"content-menu"}>

        <TabPanel value={value} index={0}>

          <TabModifyCourse history={props.history} handleClose={props.handleClose} setReload={props.setReload} setCustomAlert={props.setCustomAlert} course={props.course} />

        </TabPanel>

        <TabPanel value={value} index={1}>
         
          {show_modules ? 
            <Moduls 
              course_theme_id={course_theme_id}
              courseTheme={courseTheme}
              history={props.history} 
              setCustomAlert={props.setCustomAlert} 
              setReload={setreload} 
              course={props.course} 
              modules={modules}
              setModules={setModules}
              setShowModules={setShowModules}
            />
          :
            <Thematics
              setCourseThemeId={setCourseThemeId}
              setCourseTheme={setCourseTheme}
              history={props.history} 
              setCustomAlert={props.setCustomAlert} 
              setReload={setreload} 
              course={props.course}
              setModules={setModules}
              setShowModules={setShowModules}
              />
          }
          
        </TabPanel>
        <TabPanel value={value} index={2}>

          <CourseUsers setAlertCsv={props.setAlertCsv} setCustomAlert={props.setCustomAlert} setCount={setcount} pageUser={pageUser} rowsUser={rowsUser} count={count} setPageUser={setpageuser} setRowsUser={setrowsuser} setUsers={setusers} users={users} course={props.course} />

        </TabPanel>
        <TabPanel value={value} index={3}>

          <TeachingMaterial setCustomAlert={props.setCustomAlert} course={props.course} />

        </TabPanel>
        <TabPanel value={value} index={4}>

          <AddGroup setCustomAlert={props.setCustomAlert} groups={props.groups} course={props.course} />

        </TabPanel>
      </div>
    </React.Fragment>
  );
}