import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ExitToApp, BookOutlined, BarChart, PermIdentity } from '@material-ui/icons/'
import { Skeleton } from '@material-ui/lab'
import { Avatar, AppBar, Toolbar, IconButton, Menu, Divider } from '@material-ui/core'
import MenuLeftIcon from '@material-ui/icons/Menu'
import useColor, { useLogo, useContent } from '../../context/themeContext'
import MenuIcon from '../../components/Navbar/MenuIcon'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fff"
  },
  nav: {
    backgroundColor: "#fff"
  },
  menuButton: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  users_name: {
    color: "#999",
    fontWeight: "bold",
    marginRight: "10px",
    marginLeft: "auto"
  }
}));


export default function Nav(props) {
  
  const { COLORS } = useColor();
  const { logoUrl, brand_logo_nav } = useLogo();
  const { Content } = useContent();

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleopen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const Logout = () => {
    localStorage.clear();
    props.history.push("/login");
  };
  const GoCourses = () => {
    props.history.push("/corsi");
    handleCloseMenu();
  };

  const GoActivities = () => {
    props.history.push("/attivita");
    handleCloseMenu();
  };

  const GoProfile = () => {
    props.history.push("/profilo");
    handleCloseMenu();
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.nav}>
        <Toolbar variant="dense" style={{ minHeight: "70px" }}>
          <IconButton onClick={props.toggleDrawer(!props.open.left)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuLeftIcon style={{ width: "30px", height: "30px", color: "#999" }} />
          </IconButton>
          <div className="logo" style={{
            backgroundImage: `url(${logoUrl})`
          }}></div>

          <h3 className={classes.users_name}>
            {props.userMe?.first_name ? props.userMe?.first_name + " " + props.userMe?.last_name : <Skeleton style={{ width: "150px" }} />}
          </h3>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleopen}
          >
            <Avatar src={props.userMe?.avatar?.thumbnail} style={{ width: "30px", height: "30px", color: "#999" }} />
          </IconButton>
          {brand_logo_nav ?
            <li style={{ backgroundImage: `url(${brand_logo_nav})` }} className="logo-brand-backoffice"><a target={"_blank"} href={Content?.brand_link} /> </li>
            : null}
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuIcon
              routeAction={GoCourses}
              text={"I miei corsi"}
              Icon={<BookOutlined fontSize="small" />}
            />
            <MenuIcon
              routeAction={GoActivities}
              text={"Le mie attività"}
              Icon={<BarChart fontSize="small" />}
            />
            <MenuIcon
              routeAction={GoProfile}
              text={"Il mio profilo"}
              Icon={<PermIdentity fontSize="small" />}
            />
            <Divider />
            <MenuIcon
              routeAction={Logout}
              text={"Logout"}
              textColor={"red"}
              Icon={<ExitToApp style={{ color: "red" }} fontSize="small" />}
            />
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
}