import React from "react"
import { IconButton, Modal, Fade, Table, TableHead, TableRow, TableBody, TableCell, TableContainer } from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';
import { HighlightOff, CheckCircle, ArrowBack } from '@material-ui/icons';
import useColor from "../../context/themeContext";

const useStyles = makeStyles({
    
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: "100%",
        height: "100%"
    },
    paper: {
        maxWidth: "1200px",
        minWidth: "250px",
        maxHeight: "750px",
        minHeight: "250px",
        backgroundColor: '#ffffff',
        padding: '30px',
        borderRadius: "20px",
    },
    container: {
        maxHeight: "500px",
        minHeight: "100px",
    },
})



const RowModal = (props) => {
    const classes = useStyles();
    const { COLORS } = useColor()

    return (
        <Modal
            className={classes.modal}
            open={props.openModal}
            onClose={props.handleCloseModal}
            closeAfterTransition
        >
            <Fade in={props.openModal}>
                <div className={classes.paper}>
                    <div>
                        <IconButton onClick={props.handleCloseModal}>
                            <ArrowBack style={{ color: COLORS.primary }} />
                        </IconButton>
                    </div>
                    <TableContainer className={classes.container}>
                        <Table stickyHeader>
                            <TableHead>
                                {props.data?.additional_data?.type === "subscription" ?
                                    <TableRow>
                                        <TableCell>
                                            ID Utente
                                        </TableCell>
                                        <TableCell>
                                            Nome
                                        </TableCell>
                                        <TableCell>
                                            Cognome
                                        </TableCell>
                                        <TableCell>
                                            ID Corso
                                        </TableCell>
                                        <TableCell>
                                            Nome Corso
                                        </TableCell>
                                        <TableCell>
                                            Codice Corso
                                        </TableCell>
                                        <TableCell>
                                            Attiva
                                        </TableCell>
                                        <TableCell>
                                            Operazione effettuata
                                        </TableCell>
                                    </TableRow>
                                    :
                                    <TableRow>
                                        <TableCell>
                                            Username
                                        </TableCell>
                                        <TableCell>
                                             Nome
                                        </TableCell>
                                        <TableCell>
                                            Cognome
                                        </TableCell>
                                        <TableCell>
                                            Codice fiscale
                                        </TableCell>
                                        <TableCell>
                                            Gruppi
                                        </TableCell>
                                        <TableCell>
                                            Attiva
                                        </TableCell>
                                        <TableCell>
                                            Operazione effettuata
                                </TableCell>
                                    </TableRow>
                                }
                            </TableHead>
                            {props.data?.additional_data?.type === "subscription" ?
                                <TableBody>
                                    {
                                        props.data?.json_source?.length > 0 &&
                                        props.data?.json_source.map((row, index) => {

                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {row.user?.id}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.user?.first_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.user?.last_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.course.id}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.course?.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.course?.code}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.is_active ? <CheckCircle style={{ color: COLORS.primary, marginLeft: "15px" }} /> : <HighlightOff style={{ color: COLORS.primary, marginLeft: "15px" }} />}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.operation === "create" ? "Creato" : "Aggiornato"}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                </TableBody>

                                :

                                <TableBody>
                                    {
                                        props.data?.json_source?.length > 0 &&
                                        props.data?.json_source.map((row, index) => {

                                            return (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {row.username}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.first_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.last_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.fiscal_code}
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                        row.groups.map((el)=> `${el.id} - ${el.name}; `)
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.is_active ? <CheckCircle style={{ color: COLORS.primary, marginLeft: "15px" }} /> : <HighlightOff style={{ color: COLORS.primary, marginLeft: "15px" }} />}
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        {row.operation === "create" ? "Creato" : "Aggiornato"}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                </TableBody>

                            }
                        </Table>
                    </TableContainer>
                </div>
            </Fade>
        </Modal>
    )

}
export default RowModal;