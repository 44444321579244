import { lighten } from '@material-ui/core/styles';

export const borderRadius = 20;
export const builderComponentRadius = 20;
export const tableCellFontSize = "15px";

export const ContactClass = (COLORS) => {
	return {
		contact_box: {
		},
		title: {
			color: COLORS.primary,
			fontSize: "24px",
			fontWeight: "bold",
			paddingLeft: "16px"
		},
		flexContainer: {
			display: 'flex',
			flexDirection: 'row',
		},
		social_box: {

		},
		color: {
			color: COLORS.primary
		}
	}
}

export const recoveryPasswordClass = (COLORS) => {
	return {
		container: {
			height: "calc(100vh - 170px)",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			width: "500px",
			height: "500px",
			borderRadius: borderRadius,
			padding: "20px",
		},
		title: {
			paddingBottom: "50px",
			paddingTop: "20px",
			textAlign: "center",
			color: COLORS.primary + "!important",
			fontSize: "24px",
			fontWeight: "bold",
		},
		label: {
			padding: "20px 0",
			color: COLORS.primary + "!important",
			fontWeight: "bold",
			fontSize: "17px"
		},
		input: {
			minWidth: "180px",
			width: "100%",
			backgroundColor: "#f1f1f1",
			borderRadius: "20px",
			padding: "12px 20px"
		},
		btn_custom: {
			backgroundColor: COLORS.primary + "!important",
			fontWeight: "bold",
			color: "#fff",
			margin: "0 10px",
			width: "120px",
			height: "35px",
			borderRadius: "20px",
			'&:hover': {
				backgroundColor: COLORS.primary + "!important",
			}

		},
		containerWithPadding: {
			paddingTop: "70px",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},
		labelBack: {
			color: COLORS.primary,
			cursor: "pointer"
		},
		textSend: {
			color: COLORS.primary,
			textAlign: "center"
		},
		containerSendEmail: {
			display: "flex",
			flexDirection: "column"
		},
		iconChecked: {
			color: "green",
			alignSelf: "center",
			fontSize: "100px"
		},
		input_show: {
			backgroundColor: "#f1f1f1",
			borderRadius: "20px",
			padding: "12px 0px 12px 20px",
			"& .MuiInputBase-input": {
				padding: "0!important"
			}
		}
	}
}
export const profileClass = (COLORS) => {
	return {
		root: {
			transition: "4s!important",
			margin: "30px auto",
			padding: "25px 35px",
			borderRadius: "5px",
			maxWidth: "800px"
		},
		title: {
			color: COLORS.primary,
			fontSize: "24px",
			fontWeight: "bold"
		},
		avatar: {
			width: "100px",
			height: "100px"
		},
		subName: {
			color: COLORS.primary,
			fontSize: "20px",
			fontWeight: "bolder"
		},
		label: {
			paddingBottom: "20px",
			color: COLORS.primary,
			fontWeight: "bold",
			fontSize: "19px"
		},
		input: {
			width: "100%",
			minWidth: "200px",
			backgroundColor: "#f1f1f1",
			borderRadius: "20px",
			padding: "12px 20px"
		},
		button: {
			backgroundColor: COLORS.primary,
			color: "#fff",
			width: "50%",
			borderRadius: "20px",
			margin: "30px 0"
		},
		input_show: {
			backgroundColor: "#f1f1f1",
			borderRadius: "20px",
			padding: "12px 0px 12px 20px",
			"& .MuiInputBase-input": {
				padding: "0!important"
			}
		},
		flex_align: {
			display: "flex",
			justifyContent: "center",
			flexDirection: "column"
		}
	}
}
export const loginClass = (COLORS) => {
	return {
		container: {
			height: "calc(100vh - 170px)",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			width: "500px",
			height: "500px",
			borderRadius: borderRadius,
			padding: "20px",
		},
		closeicon: {
			display: "flex",
			color: "#999",
			alignItems: "center",
			justifyContent: "flex-end"

		},
		title: {
			paddingBottom: "50px",
			paddingTop: "20px",
			textAlign: "center",
			color: COLORS.primary + "!important",
			fontSize: "24px",
			fontWeight: "bold",
		},
		label: {
			padding: "20px 0",
			color: COLORS.primary + "!important",
			fontWeight: "bold",
			fontSize: "17px"
		},
		input: {
			minWidth: "180px",
			width: "100%",
			backgroundColor: "#f1f1f1",
			borderRadius: "20px",
			padding: "12px 20px"
		},
		input_show: {
			backgroundColor: "#f1f1f1",
			borderRadius: "20px",
			padding: "12px 0px 12px 20px",
			"& .MuiInputBase-input": {
				padding: "0!important"
			}
		},
		btn_save: {
			backgroundColor: COLORS.primary + "!important",
			fontWeight: "bold",
			color: "#fff",
			margin: "0 10px",
			width: "90px",
			height: "35px",
			borderRadius: "20px",
			'&:hover': {
				backgroundColor: COLORS.primary + "!important",
			}

		},
		container_btn: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		containerRecovery: {
			padding: "30px 90px 50px 0",
			display: "flex",
			justifyContent: "flex-end"
		},
		labelRecovery: {
			color: COLORS.primary,
			cursor: "pointer"
		}
	}
}
export const forgotPasswordClass = (COLORS) => {
	return {
		container: {
			height: "calc(100vh - 170px)",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			width: "500px",
			height: "500px",
			borderRadius: borderRadius,
			padding: "20px",
		},
		title: {
			paddingBottom: "50px",
			paddingTop: "20px",
			textAlign: "center",
			color: COLORS.primary + "!important",
			fontSize: "24px",
			fontWeight: "bold",
		},
		label: {
			padding: "20px 0",
			color: COLORS.primary + "!important",
			fontWeight: "bold",
			fontSize: "17px"
		},
		input: {
			minWidth: "180px",
			width: "100%",
			backgroundColor: "#f1f1f1",
			borderRadius: "20px",
			padding: "12px 20px"
		},
		btn_custom: {
			backgroundColor: COLORS.primary + "!important",
			fontWeight: "bold",
			color: "#fff",
			margin: "0 10px",
			width: "120px",
			height: "35px",
			borderRadius: "20px",
			'&:hover': {
				backgroundColor: COLORS.primary + "!important",
			}

		},
		containerWithPadding: {
			paddingTop: "70px",
			display: "flex",
			justifyContent: "center",
			alignItems: "center"
		},
		labelBack: {
			color: COLORS.primary,
			cursor: "pointer"
		},
		textSend: {
			color: COLORS.primary,
			textAlign: "center"
		},
		containerSendEmail: {
			display: "flex",
			flexDirection: "column"
		},
		iconChecked: {
			color: "green",
			alignSelf: "center",
			fontSize: "100px"
		}
	}
}

export const changePasswordClass = (COLORS) => {
	return {
		container: {
			height: "calc(100vh - 170px)",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			width: "500px",
			height: "500px",
			borderRadius: borderRadius,
			padding: "20px",
		},
		closeicon: {
			display: "flex",
			color: "#999",
			alignItems: "center",
			justifyContent: "flex-end"

		},
		title: {
			paddingBottom: "50px",
			paddingTop: "20px",
			textAlign: "center",
			color: COLORS.primary + "!important",
			fontSize: "24px",
			fontWeight: "bold",
		},
		label: {
			padding: "20px 0",
			color: COLORS.primary + "!important",
			fontWeight: "bold",
			fontSize: "17px"
		},
		input: {
			minWidth: "180px",
			width: "100%",
			backgroundColor: "#f1f1f1",
			borderRadius: "20px",
			padding: "12px 20px"
		},
		input_show: {
			backgroundColor: "#f1f1f1",
			borderRadius: "20px",
			padding: "12px 0px 12px 20px",
			"& .MuiInputBase-input": {
				padding: "0!important"
			}
		},
		btn_save: {
			backgroundColor: COLORS.primary + "!important",
			fontWeight: "bold",
			color: "#fff",
			margin: "0 10px",
			width: "90px",
			height: "35px",
			borderRadius: "20px",
			'&:hover': {
				backgroundColor: COLORS.primary + "!important",
			}

		},
		container_btn: {
			paddingTop: "70px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",

		},
		chip_message: {
			borderColor: COLORS.primary,
			color: COLORS.primary,
			backgroundColor: "#f1f1f1",
		}
	}
}

export const questionClass = (COLORS) => {
	return {
		container_add: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			marginTop: "10px",
		},
		button_add_answer: {
			width: "100%",
			height: "60px",
			margin: "0 10px",
			border: "2px solid " + COLORS.primary,
			boxShadow: "none",
			backgroundColor: "#fff",
			color: COLORS.primary,
			borderRadius: "20px",
			textTransform: "capitalize",

			'&:hover': {
				backgroundColor: "#fff",
				color: COLORS.primary,
				borderRadius: "20px",
			}
		},
		draggable: {
			maxWidth: "300px",
		},
		answer: {
			display: "flex",
			alignItems: "center",
			cursor: "grab!important",
			padding: "10px",
			margin: "10px",
			borderRadius: "20px",
			color: "#fff",
			background: lighten(COLORS?.primary, 0.1)
		},
		typo: {
			width: "100%"
		},
		icon: {
			backgroundColor: COLORS.primary,
			color: "#fff",

			'&:hover': {
				backgroundColor: COLORS.primary,
				color: "#fff",
			}
		},
		icon_font: {
			fontSize: "20px",
		},
		icon_drag: {
			cursor: "grabbing"
		},
		center: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		}
	}
}
export const builderClass = (COLORS) => {
	return {
		container_icon_button: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		text_q: {
			fontSize: "14px",
			display: "flex",
			width: "200px",
			alignItems: "center",
		},
		optional_question: {
			marginLeft: "30px",
			marginTop: "20px",
			display: "flex",
		},
		question_list: {
			display: "flex",
			alignItems: "center",
			cursor: "grab!important",
			padding: "12px",
			margin: "10px 5% 0",
			background: lighten(COLORS?.primary, 0.1),
			color: "#fff",
			borderRadius: builderComponentRadius
		},
		question_modify_list: {
			cursor: "grab!important",
			padding: "12px",
			margin: "10px 5% 0",
			background: lighten(COLORS?.primary, 0.1),
			color: "#fff",
			borderRadius: builderComponentRadius
		},
		question: {
			display: "flex",
			alignItems: "center",
			cursor: "grab!important",
			padding: "12px",
			margin: "10px 5% 0",
			background: lighten(COLORS?.primary, 0.1),
			color: "#fff",
			borderTopLeftRadius: "20px",
			borderTopRightRadius: "20px"
		},
		question_opt: {
			display: "flex",
			alignItems: "center",
			cursor: "grab!important",
			padding: "12px",
			margin: "10px 5% 0",
			background: lighten(COLORS?.primary, 0.1),
			color: "#fff",
			borderBottomLeftRadius: "20px",
			borderBottomRightRadius: "20px"
		},
		question_add: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			padding: "5px",
			margin: "20px 5%",
			border: `2px solid ${lighten(COLORS?.primary, 0.1)}`,
			borderRadius: builderComponentRadius,
			color: lighten(COLORS.primary, 0.3),
			cursor: "pointer",

			'&:hover': {
				boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
			}
		},
		preview: {
			backgroundColor: "rgba(150, 150, 200, 0.1)",
			border: "1px dashed #abc",
			borderRadius: "12px",
			margin: "20px 5%",
		},
		selected: {
			'&.Mui-selected': {
				color: COLORS.primary + "!important"
			}
		},
		select: {
			margin: "0 10px",
			"& .MuiSelect-root": {
				paddingRight: "32px!important",
				background: "#fff"
			}
		},
		summaryAccordion: {
			border: "none",
			boxShadow: "none",
			margin: 0,
			width: "100%",
			alignItems: "center",
			'& .MuiAccordionSummary-content, .Mui-expanded': {
				margin: 0,
			}
		}, icon_drag: {
			cursor: "grabbing"
		},
		cont_drag_icon: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			marginRight: "20px"
		}
	}
}
export const answerClass = (COLORS) => {
	return {
		container_add: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			marginTop: "10px",
		},
		button_add_answer: {
			width: "100%",
			height: "60px",
			margin: "0 10px",
			border: "2px solid " + COLORS.primary,
			boxShadow: "none",
			backgroundColor: "#fff",
			color: COLORS.primary,
			borderRadius: "20px",
			textTransform: "capitalize",

			'&:hover': {
				backgroundColor: "#fff",
				color: COLORS.primary,
				borderRadius: "20px",
			}
		},
		answer: {
			minWidth: "300px",
			margin: "10px",
			display: "flex",
			alignItems: "center",
			cursor: "grab!important",
			padding: "10px",
			borderRadius: builderComponentRadius,
			color: "#fff",
			background: lighten(COLORS?.primary, 0.1)
		},
		typo: {
			width: "100%"
		},
		typo_config: {
			width: "80%",
			margin: "0 auto",
			paddingLeft: "20px"
		},
		icon: {
			backgroundColor: COLORS.primary,
			color: "#fff",

			'&:hover': {
				backgroundColor: COLORS.primary,
				color: "#fff",
			}
		},
		icon_font: {
			fontSize: "20px",
		},
		icon_drag: {
			cursor: "grabbing"
		},
		preview: {
			backgroundColor: "rgba(150, 150, 200, 0.1)",
			border: "1px dashed #abc",
			borderRadius: "12px",
			width: "80%",
			margin: "20px 10% 20px 10%"
		}
	}
}
export const modalGroup = (COLORS) => {
	return {
		root: {
			flexGrow: 1,
			boxShadow: "none!important"
		},
		tab: {
			color: "#999",
			fontWeight: "bold",
			fontSize: "18px",
			"&.Mui-selected": {
				color: COLORS.primary + "!important"
			},
			"&.MuiTab-root": {
				minWidth: "auto!important",
				padding: "20px 20xp"
			}
		},
		tabs: {
			width: "95%",
			float: "left",
			"& .MuiTabs-indicator": {
				backgroundColor: COLORS.primary + "!important"
			}
		},
		color: {
			color: COLORS.primary + "!important"
		},
		modal: {
			zIndex: "0!important",
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		paper: {
			position: "relative!important",
			width: "400px",
			marginLeft: "200px",
			marginRight: "60px",
			height: "600px",
			borderRadius: borderRadius,
			padding: "20px",
		},
		closeicon: {
			display: "flex",
			color: "#999",
			alignItems: "center",
			justifyContent: "flex-start",
			paddingLeft: "20px",
			paddingTop: "20px",
			paddingBottom: "20px",
			borderBottom: "1px solid #f1f1f1"
		},
		title: {
			color: COLORS.primary + "!important",
			fontSize: "20px",
			fontWeight: "bold",
			paddingLeft: "20px"
		},
		subtitle: {
			fontWeight: "bold",
			paddingBottom: "10px"
		},
		label: {
			padding: "20px 0",
			color: COLORS.primary + "!important",
			fontWeight: "bold",
			fontSize: "17px"
		},
		textarea: {
			width: "100%",
			backgroundColor: "#f1f1f1",
			borderRadius: "20px",
			padding: "12px 20px"
		},
		select: {
			width: "100%"
		},
		input: {
			width: "100%",
			backgroundColor: "#f1f1f1",
			borderRadius: "20px",
			padding: "12px 20px"
		},
		btn_del_user: {
			backgroundColor: "red",
			opacity: "0.8",
			color: "#fff",
			marginRight: "auto",
			width: "130px",
			height: "45px",
			borderRadius: "20px",
			'&:hover': {
				backgroundColor: "red",
				opacity: "1",
			}
		},
		btn_del: {
			padding: "20px 60px",
			backgroundColor: "#f1f1f1",
			margin: "0 10px",
			width: "130px",
			height: "45px",
			borderRadius: "20px",
			'&:hover': {
				backgroundColor: "#f1f1f1"
			}
		},
		btn_save: {
			padding: "20px 60px",
			backgroundColor: COLORS.primary,
			color: "#fff",
			margin: "0 10px",
			width: "130px",
			height: "45px",
			borderRadius: "20px",
			'&:hover': {
				backgroundColor: COLORS.primary
			}
		},
		save: {
			position: "absolute",
			width: "100%",
			bottom: "0",
			left: "0",
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			padding: "20px 50px",
			borderTopLeftRadius: "0",
			borderTopRightRadius: "0",
			borderBottomLeftRadius: "20px",
			borderBottomRightRadius: "20px",
		},
		link: {
			display: "flex",
			alignItems: "center"
		},
		link_active: {
			display: "flex",
			alignItems: "center",
			color: "#808080",
			fontWeight: "bold"
		},
		icon: {
			marginRight: "2px",
			width: 22,
			height: 22,
		},
		span: {
			display: "flex",
			alignItems: "center",
			height: 22,
		},
		btn_active: {
			backgroundColor: COLORS.primary + "!important",
			fontWeight: "bold",
			color: "#fff!important",
			maxWidth: "300px",
			borderRadius: "15px",
			padding: "15px 10px"
		},
		btn_not_active: {
			fontWeight: "bold",
			color: COLORS.primary + "!important",
			maxWidth: "300px",
			borderRadius: "15px",
			padding: "15px 10px",
		}

	}
}
export const modalClass = (COLORS) => {
	return {
		tab: {
			color: "#999",
			fontWeight: "bold",
			fontSize: "18px",
			"&.Mui-selected": {
				color: COLORS.primary + "!important"
			},
			"&.MuiTab-root": {
				minWidth: "auto!important",
				padding: "20px 20xp"
			}
		},
		tabs: {
			position: "fixed",
			width: "95%",
			float: "left",
			"& .MuiTabs-indicator": {
				backgroundColor: COLORS.primary + "!important"
			}
		},
		color: {
			color: COLORS.primary + "!important"
		},
		modal: {
			zIndex: "0!important",
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		paper: {
			position: "relative!important",
			width: "600px",
			marginLeft: "200px",
			marginRight: "60px",
			height: "600px",
			borderRadius: borderRadius,
			padding: "20px",
		},
		closeicon: {
			display: "flex",
			color: "#999",
			alignItems: "center",
			justifyContent: "flex-start",
			paddingLeft: "20px",
			paddingTop: "20px",
			paddingBottom: "20px",
			borderBottom: "1px solid #f1f1f1"
		},
		title: {
			color: COLORS.primary + "!important",
			fontSize: "20px",
			fontWeight: "bold",
			paddingLeft: "20px"
		},
		title_course: {
			color: COLORS.primary + "!important",
			fontSize: "18px",
			fontWeight: "bold"
		},
		subtitle: {
			fontWeight: "bold",
			paddingBottom: "10px"
		},
		label: {
			padding: "20px 0",
			color: COLORS.primary + "!important",
			fontWeight: "bold",
			fontSize: "17px"
		},
		select: {
			width: "100%"
		},
		inputNumber: {
			minWidth: "80px",
			backgroundColor: "#f1f1f1",
			borderRadius: "20px",
			padding: "12px 20px"
		},
		input: {
			minWidth: "180px",
			width: "100%",
			backgroundColor: "#f1f1f1",
			borderRadius: "20px",
			padding: "12px 20px"
		},
		inputDate: {
			minWidth: "150px",
			backgroundColor: "#f1f1f1",
			borderRadius: "20px",
			padding: "12px 20px"
		},
		textarea: {
			width: "100%",
			backgroundColor: "#f1f1f1",
			borderRadius: "20px",
			padding: "12px 20px"
		},
		btn_del: {
			padding: "20px 60px",
			backgroundColor: "#f1f1f1",
			fontWeight: "bold",
			margin: "0 10px",
			width: "90px",
			height: "35px",
			borderRadius: "20px",
			'&:hover': {
				backgroundColor: "#f1f1f1"
			}
		},
		btn_save: {
			padding: "20px 60px",
			backgroundColor: COLORS.primary + "!important",
			fontWeight: "bold",
			color: "#fff",
			margin: "0 10px",
			width: "90px",
			height: "35px",
			borderRadius: "20px",
			'&:hover': {
				backgroundColor: COLORS.primary + "!important",
			}
		},
		btn_reset: {
			padding: "20px 35px",
			backgroundColor: COLORS.primary + "!important",
			fontWeight: "bold",
			color: "#fff",
			margin: "0 10px",
			height: "35px",
			borderRadius: "20px",
			'&:hover': {
				backgroundColor: COLORS.primary + "!important",
			}
		},
		container_btn: {
			position: "fixed",
			width: "100%",
			paddingTop: "25px",
			paddingBottom: "25px",
			paddingLeft: "25px",
			bottom: "0px",
			display: "flex",
			alignItems: "center",
			borderTop: "1px solid #f1f1f1",
			backgroundColor: "#fff"
		},
		selected: {
			'&.Mui-selected': {
				color: COLORS.primary + "!important"
			}
		},
		btn_active: {
			backgroundColor: COLORS.primary + "!important",
			fontWeight: "bold",
			color: "#fff!important",
			width: "200px",
			borderRadius: "15px"
		},
		btn_not_active: {
			fontWeight: "bold",
			color: COLORS.primary + "!important",
			width: "200px",
			borderRadius: "15px"
		},
		save: {
			marginTop: "auto",
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			padding: "20px 50px"
		},
		btn_del_user: {
			backgroundColor: "red",
			opacity: "0.8",
			color: "#fff",
			marginRight: "auto",
			width: "130px",
			height: "45px",
			borderRadius: "20px",
			'&:hover': {
				backgroundColor: "red",
				opacity: "1",
			}
		},
	}
}

export const StatisticsClass = {
	root: {
		minHeight: "500px",
		width: "1200px",
		margin: "30px auto"
	},
	circleAvatar: {
		height: "140px",
		width: "140px",
		backgroundColor: "#ccc",
	},
	subCharts: {
		fontWeight: "bold",
		color: "#000",
		fontSize: "18px",
		width: "100%",
		textAlign: "center"
	},
	title: {
		fontWeight: "bold",
		color: "#000",
		fontSize: "22px",
		width: "100%",
		display: "flex",
	},
	subtitle: {
		color: "#000",
		fontSize: "18px"
	},
	flex_align: {
		display: "flex",
		justifyContent: "flex-start",
		borderBottom: "1px solid #ccc",
		padding: "15px 0px",

		'& p:first-child': {
			minWidth: "80px"
		}
	},
	vertical_alignment: {
		justifyContent: "center",
		flexDirection: "column",
		display: "flex"
	},
	padding_grid: {
		padding: "20px"
	},
	max_grid: {
		margin: "0 auto"
	}
}

export const listMenu = (COLORS) => {
	return {
		listMenu: {
			position: "absolute",
			top: "20%",
			right: "50px",
			display: "flex",
			flexDirection: "column",
			'& li': {
				fontSize: "18px",
				background: "#fff",
				color: COLORS.primary,
				listStyleType: "none",
				margin: "10px",
				borderRadius: "20px",
				padding: " 8px 10px",
				display: "inline-block",
				textAlign: "center",
				fontWeight: "bold",
				cursor: "pointer"
			}
		}
	}
}

////SELECT
export const select = {
	'& .MuiSelect-root': {
		backgroundColor: "#fff!important",
		minWidth: "120px",
		border: "1px solid #ccc",
		padding: "12px 25px!important"
	}
}

///ButtonSuccess
export const buttonSuccess = (COLORS) => {
	return {
		buttonSuccess: {
			backgroundColor: COLORS.primary,
			'&:hover': {
				backgroundColor: COLORS.primary,
			},
		}
	}

}
///ButtonProgress
export const buttonProgress = {
	color: "#fff",
	position: 'absolute',
	top: '50%',
	left: '50%',
	marginTop: -12,
	marginLeft: -12,
}

export const filterIcon = {
	color: "#ccc",
	width: "40px",
	height: "40px",
	marginLeft: "10px",
	cursor: "pointer"
}

export const filterIconActive = (COLORS) => {
	return {
		filterIconActive: {
			color: COLORS.primary,
			width: "40px",
			height: "40px",
			marginLeft: "10px",
			cursor: "pointer"
		}
	}
}

export const colorLabel = (COLORS) => {
	return {
		colorLabel: {
			'&.Mui-selected': {
				color: COLORS.primary + "!important"
			}
		}
	}
}


///TABLE
export const tableStyle = (COLORS) => {
	return {
		container: {
			padding: "20px",
			background: "#fff",
			borderRadius: borderRadius
		},
		root: {
			width: '100%',
			position: "relative",
			borderRadius: borderRadius,
			padding: "20px"
		},
		paper: {
			padding: "20px",
			background: "#fff",
			borderRadius: borderRadius,
		},
		no_paper: {
			padding: "20px",
			border: "none",
			boxShadow: "none"
		},
		table: {
			width: "100%"
		},
		visuallyHidden: {
			border: 0,
			clip: 'rect(0 0 0 0)',
			height: 1,
			margin: -1,
			overflow: 'hidden',
			padding: 0,
			position: 'absolute',
			top: 20,
			width: 1,
		},
		table_label: {
			color: COLORS.primary + "!important",
			fontWeight: "bold!important",
			fontSize: "18px!important"
		},
		label_cell: {
			color: "#333!important",
			fontSize: tableCellFontSize + "!important",
			"&.MuiTableCell-sizeSmall": {
				padding: "2px 10px 2px 10px!important"
			}
		},
		label_cell_click: {
			color: COLORS.primary + "!important",
			fontSize: tableCellFontSize + "!important",
			cursor: "pointer",
			"&.MuiTableCell-sizeSmall": {
				padding: "2px 10px 2px 10px!important"
			}
		},
		check: {
			marginRight: "20px!important",
			'&.Mui-checked': {
				color: COLORS.primary,
			}
		},
		menu_table: {
			color: COLORS.primary + "!important"
		},
		menu_table_del: {
			color: "red",
		},
		selected: {
			'&.Mui-selected': {
				color: COLORS.primary + "!important"
			}
		},
		rowSelected: {
			"&.Mui-selected": {
				backgroundColor: lighten(COLORS?.primary, 0.85),
			},
			"&.Mui-selected:hover": {
				backgroundColor: lighten(COLORS?.primary, 0.65),
			}
		},
		highSelected: {
			"&.Mui-selected": {
				backgroundColor: lighten(COLORS?.primary, 0.85),
			},
			"&.Mui-selected:hover": {
				backgroundColor: lighten(COLORS?.primary, 0.65),
			}
		},
		chip: {
			backgroundColor: COLORS.primary,
			color: "#fff"
		},
		tabs: {
			paddingBottom: "30px"
		},
		tab: {
			color: "#999",
			fontWeight: "bold",
			fontSize: "20px",

			"&.Mui-selected": {
				color: COLORS.primary + "!important"
			}
		}, optionsView: {
			borderRadius: borderRadius,
			padding: "20px",
			marginBottom: "30px"
		}
	}
}

///TABS
export const tabsStyle = (COLORS) => {
	return {
		paperPad: {
			padding: "20px"
		},
		root: {
			flexGrow: 1,
			boxShadow: "none!important"
		},
		container: {
			background: "#fff",
			borderRadius: borderRadius,
			width: '100%',
			position: "relative"
		},
		arrow: {
			width: "5%",
			float: "left",
			height: "48px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			cursor: "pointer"
		},
		arrowicon: {
			color: "#999"
		},
		content: {
			paddingTop: "20px",
			paddingBottom: "65px",
		},
		title: {
			fontSize: "20px",
			paddingBottom: "10px",
			color: COLORS.primary,
			fontWeight: "bold"
		},
		subtitle: {
			fontSize: "18px",
			fontWeight: "bold"
		},
		input: {
			
			backgroundColor: "#f1f1f1",
			borderRadius: "20px",
			padding: "12px 10px"

		},
		input_link: {
			minWidth: "180px",
			width: "100%",
			backgroundColor: "#f1f1f1",
			borderRadius: "20px",
			padding: "12px 20px"
		},
		label: {
			paddingBottom: "20px",
			color: COLORS.primary,
			fontWeight: "bold",
			fontSize: "19px"
		},
		select: {
			width: "100%",
			maxWidth: "235px"
		},
		textarea: {
			width: "100%",
			backgroundColor: "#f1f1f1",
			borderRadius: "20px",
			padding: "12px 20px"
		},
		color: {
			color: COLORS.primary + "!important"
		},
		closeicon: {
			display: "flex",
			color: "#999",
			alignItems: "center",
			justifyContent: "flex-end"

		}, save: {
			position: "absolute",
			width: "100%",
			bottom: "0",
			left: "0",
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			padding: "20px 50px",
			borderTopLeftRadius: "0",
			borderTopRightRadius: "0",
			borderBottomLeftRadius: "20px",
			borderBottomRightRadius: "20px",
		},
		save_modal: {
			position: "absolute",
			width: "100%",
			bottom: "0",
			left: "0",
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			padding: "20px 50px",
		},
		btn_del_user: {
			backgroundColor: "red",
			opacity: "0.8",
			color: "#fff",
			marginRight: "auto",
			width: "130px",
			height: "45px",
			borderRadius: "20px",
			'&:hover': {
				backgroundColor: "red",
				opacity: "1",
			}
		},
		btn_del: {
			backgroundColor: "#f1f1f1",
			margin: "0 10px",
			width: "130px",
			height: "45px",
			borderRadius: "20px",
			'&:hover': {
				backgroundColor: "#f1f1f1"
			}
		},
		btn_save: {
			backgroundColor: COLORS.primary,
			color: "#fff",
			margin: "0 10px",
			width: "130px",
			height: "45px",
			borderRadius: "20px",
			'&:hover': {
				backgroundColor: COLORS.primary
			}
		},
		selected: {
			'&.Mui-selected': {
				color: COLORS.primary + "!important"
			}
		},
		container_btn: {
			padding: "30px 15px",
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			position: "absolute",
			bottom: "0",
			right: "0"
		},
		input_show: {
			backgroundColor: "#f1f1f1",
			borderRadius: "20px",
			padding: "12px 0px 12px 20px",
			"& .MuiInputBase-input": {
				padding: "0!important"
			}
		},
		tabs: {
			width: "95%",
			float: "left",
			"& .MuiTabs-indicator": {
				backgroundColor: COLORS.primary + "!important"
			}
		},
		tab: {
			color: "#999",
			fontWeight: "bold",
			fontSize: "20px",
			"&.Mui-selected": {
				color: COLORS.primary + "!important"
			}
		},
		span: {
			display: "flex",
			alignItems: "center",
			height: 22,
		},
		link: {
			display: "flex",
			alignItems: "center"
		},
		link_active: {
			display: "flex",
			alignItems: "center",
			color: "#808080",
			fontWeight: "bold"
		}
	}
}